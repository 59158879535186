.pt_storefront {
	#main { 
		h1 {
			font-size: 70px;
			@include WorkSansBold($WorkSans);
		}
		h2:not(.recommendation-heading):not(.dont-use-legacy-sitegen) { 
			font-size: 60px;
			@include WorkSansThin($WorkSans);
		}
		h3 {
			font-size: 50px;
			@include WorkSansBold($WorkSans);
		}
		h4 {
			font-size: 40px;
		}
	
		h1,h2,h3, {
			margin: 0 0;
			
		}
		
		.text-parent {
			.wrap-center {
			    top: 33.9%;
			}
			.text-content {
				.promo-red {
					@include WorkSansBold($WorkSans);
				}
			}
		}
		.banner {			
			&.text-parent {
				.wrap-center {
					.text-content {
						span {
							@include WorkSansThin($WorkSans);
						}
					} 
				}	
			}
		}
		.wide-one {
			width:100%;
			margin-bottom: 20px;
			
			&.text-parent {
				.wrap-center {
					width: 100%;
					top: 34.5%;
					.text-content {
						h1 {
							letter-spacing: 18px;
							color: $white;	
							font-size: 80px;					
						}
						span {
							font-size: 30px;
							color: $white;
							width: 100%;
    						display: inherit;
							@include LoraItalic($Lora);
							padding-top: 4px;
						}
						button {
							margin-top: 30px;
							background-color: $white;
    						color: $black;
    						padding: 15px 37px 15px 36px;
						}
					} 
				}	
			}
		}
		.promo-wide {
			background: $light-pink;
			background: -moz-linear-gradient(-45deg,  $light-pink 0%, $light-pink 45%, $light-blue 69%, $light-blue 100%);
			background: -webkit-linear-gradient(-45deg,  $light-pink 0%,$light-pink 45%,$light-blue 69%,$light-blue 100%);
			background: linear-gradient(135deg,  $light-pink 0%,$light-pink 45%,$light-blue 69%,$light-blue 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$light-pink', endColorstr='$light-blue',GradientType=1 );			
		    padding: 100px 20px 84px; 
		    margin: -94px 0 20px;
		    h1 {
		   		letter-spacing: 13px;
		   		line-height: 55px;
		   		margin-top: 9px;
		    }
		    h2 {
		    	@include WorkSansExtraLight($WorkSans);
		    	margin-top: 44px;
    			letter-spacing: 13px;
    			line-height: 60px;
    			font-size: 70px;
		    }
		    button{
		    	margin-top: 16px;
		    	padding: 15px 35px;
		    }
		    .cc-code {
		    	.code {
		    		@include WorkSansBold($WorkSans);
		    	}
		    }
		}
		
		// ww-home-asset2 styles
		.bottom-banner {
			.text-content {
		    	width: 53.7%;
		    	color: $white;
		    	.heading {
		    		@include WorkSansLight($WorkSans);
		    		font-size: 35px;
		    		width: 100%;
		    		text-transform: uppercase;
		    		margin-bottom: 7px;
		    		span{
		    			@include WorkSansLight($WorkSans);
			    		font-size: 35px;
			    		width: auto;
			    		text-transform: uppercase;
			    		margin-bottom: 12px;
			    		font-style: normal;
			    		vertical-align: text-bottom;
		    		}
		    	}
		    	h1 {
		    		width: auto;
    				display: inline-block;
	    		    letter-spacing: 10px;
		    	}
		    	span {
		    		@include LoraItalic($Lora);
		    		font-size: 18px;
		    		width: 100%;
   					display: inline-block;
		    	}
		    	button {
		    		margin: 36px 0 0 6%;
		    		font-size: 15px;
		    		padding: 14px 46px;
		    	}
		    }
		}
		.collections {
		    display: inline-block;
    		width: 100%;
    		margin: 59px 0;
    		h5 {
    			@include LoraItalic($Lora);
    			font-size: 24px;
    			text-align: center;
    			margin: 0 0;
    			font-weight: normal;
    			margin-bottom: 2px;
    		}
    		h3.heading {
			    letter-spacing: 7px;
			    line-height: 46px;
		        margin-bottom: 33px;
    		}
		    .collections-tile {
				position: relative;
				width: 33.33%;
				max-width: 415px;
				float: left;
				margin-right: 7px;
				
				&.last {
					margin-right: 0px;
				}
				&.clearance-off-tile {
					.text-info {
	   					bottom: 27%;   					
					}
				}
				.text-info {
					position: absolute;
   					bottom: 11%;
   					text-align: center;
   					color: $white;
   					width:100%;
				}
				span{
				
					&.text1 {
						@include WorkSansMedium($WorkSans);
						font-size: 15px;
						letter-spacing: 2px;
						text-transform: uppercase;
					}
					&.text3 {
						@include LoraItalic($Lora);
						font-size: 24px;
					}
				}
				&.limited-off-tile {
					h1 {
						@include WorkSansBlack($WorkSans); 
						letter-spacing: 5px;
						line-height: 55px;
						margin:9px 0 6px 0;
						span {
							@include WorkSansThin($WorkSans);
							letter-spacing: 20px;
						}
					}
				}
				&.clearance-off-tile {
					h1 {
						letter-spacing: 15px;
    					font-size: 115px;
    					line-height: 100px; 
					}
					h2 {
						font-size: 115px;
					    letter-spacing: 30px;
   						line-height: 100px;
   						margin-bottom: 5px;
					}
				}
				&.lookbook-off-tile {
					h1 {
						letter-spacing: 5px;
    					line-height: 70px;
					}	
					.text-info{
						bottom: 10.6%;
					}			
				}
			}
		}

		@media screen and (min-width: 1024px){
			.wide-one {
				width: auto;
			}
		}
		
		@media screen and (max-width: 1024px) {
			.home-inner-wrapper {
    			box-sizing: border-box;
			}
			
			.wide-one {
				&.text-parent {
					.wrap-center {
						.text-content {
							h1 {
								letter-spacing: 18px;
								font-size: 70px;					
							} 
							span {
								
							}
							button {
								margin-top: 30px;
							}
						} 
					}	
				}
			}
			.promo-wide {
				padding: 62px 20px 53px;
			}
			
			.bottom-banner {
				&.text-parent {
					.wrap-center {
						top: 33%;
					}
				}
				.text-content {
			    	h1 {
			    		margin-bottom: 9px;
			    	}
			    	button {
			    		margin: 35px 0 0;
			    	}
			    }
			}
			.collections {
				margin: 50px 0 54px;
		   		.collections-tile {
		   			width: 32.80%;
		   			span{
						&.text1 {
							font-size: 12px;
						}
						&.text3 {
							font-size: 18.75px;
						}
					}
					
					&.limited-off-tile {
						h1 {
							font-size: 54px;
							line-height: 54px;
							span {
								letter-spacing: 20px;
							}
						}
					}
					&.clearance-off-tile {
						h1 {
							font-size: 90px;
    						line-height: 90px;
						}
						h2 {
							font-size: 90px;
						    letter-spacing: 24px;
						    line-height: 63px;
						}
						span {
							&.text3 {
								margin-top: 10px;
							    display: inherit;
							}
						}
						.text-info {
							bottom: 27%;
						}
					}
					&.lookbook-off-tile {
						h1 {
							line-height: 54px;
    						font-size: 54px;
						}				
					}
		   		}
			}
		}
		
		//iPad and below
		@media screen and (max-width:1023px){
			.home-inner-wrapper {
				padding: 0 0;
			}
			.wide-one {
				margin-bottom: 0px;
				&.text-parent {
					.wrap-center {
						.text-content {
							h1 {
								font-size: 60px;					
							} 
							span {
								font-size: 26px;
							}	
							button {
								margin-top: 30px;
							}
						} 
					}	
				}
			}
			.promo-wide {
				margin: -3px 0;
				padding: 42px 20px 53px;
				h1,h2 {
					font-size: 60px;
				}
			}
		}
		
		@media screen and (min-width:768px) and (max-width:1023px) {
			.collections {
			    padding: 0 1%;
		 		box-sizing: border-box;
		 		margin-bottom: 60px;
				h3{
					&.heading{
						margin-bottom: 25px;
					}
				}
		   		.collections-tile {
		   			width: 32.60%;
		   			h3 {
		   				&.heading {
	   					    margin-bottom: 24px;
		   				}
		   			}
		   			span{
						&.text1 {
							font-size: 10px;
						}
						&.text3 {
							font-size: 15px;
						}
					}
					
					&.limited-off-tile {
						h1 {
							font-size: 42px;
   							line-height: 35px;
   							margin: 0px 0 4px 0;
							span {
								letter-spacing: 5px;
							}
						}
					}
					&.clearance-off-tile {
						h1 {
							font-size: 68.5px;
    						line-height: 68.5px;
						}
						h2 {
						    letter-spacing: 20px;
						    font-size: 68.5px;
    						line-height: 50px;
						}
						span {
							&.text3 {
								margin-top: 10px;
							    display: inherit;
							}
						}
					}
					&.lookbook-off-tile {
						h1 {
							line-height: 42px;
    						font-size: 42px;
						}				
					}
		   		}
			}
			.wide-one{
				&.text-parent{
					.wrap-center{
						top: 30.5%;
						.text-content{
							span{
								padding-top: 10px;
							}
							button{
								margin-top: 32px;
							}
						}
					}
		}
			}
			.promo-wide{
				h2{
					margin-top: 34px;
				}
				h1{
					margin-top: 0;
				}
				button{
					margin-top: 12px;
				}
			}
			.bottom-banner{ 
				.text-content{
					.heading{
						margin-bottom: 0;
					}
					button{
						margin: 33px 20% 0 0;
					}
				}
				&.text-parent{ 
					.wrap-center{
						top: 33%;
					}
				}
			}
		}
		
		@media screen and (max-width:767px){
			.wide-one {
				&.text-parent {
					.wrap-center {
						position: static;
    					width: 100%;
    					margin-top: 13px;
    					margin-bottom: 40px;
						.text-content {
							h1 {
								font-size: 30px;
								color: $black;
								letter-spacing: 6px;
							    width: 100%;
   								margin: auto;
							} 
							span {
								color: $black;
								font-size: 15px;
								padding-top: 3px;
							}
							button {
								margin-top: 18px;
   								border-color: $black;
   								padding: 15px 11.4%;
							}	
						} 
					} 
				}
			}
			.promo-wide {
				margin: 0 0 20px 0;
				padding: 62px 6px;
				h2 , h1 {
					font-size: 40px;
				}
				button{
					padding: 15px 11.1%;
					margin-top: 16px;
				}
			}
			.bottom-banner {
				.text-content {
					width: 100%;
					span{
						font-size: 15px;
					}
					button{
						font-size: 14px;
						padding: 15px 19.8%;
						margin: 25px 0 0;
					}
					.heading{
						font-size: 22.5px;
						margin-bottom: 2px;
						span{
							font-size: 22.5px;
							margin-bottom: 6px;
						}
						h1{
							font-size: 45.5px;
						}
					}
				}
				&.text-parent {
					.wrap-center {
						top: 51.2%;
					}
				}
			}
			
			.collections {
			    margin: 39px 0 30px;
				h5{
					font-size: 15px;
				}
				h3{
					font-size: 30px;
					&.heading{
						line-height: 31px;
					}
				}
		   		.collections-tile {
		   			width: 100%;
		   			float: none;
    				margin: 0 auto 30px;
    				span{
    					&.text3{
    						font-size: 12px;
    					}
    					&.text1{
                            font-size: 7.5px;
                    	}
    				}
    				&.limited-off-tile{
    					h1{
    						font-size: 34px;
    					}
    				}
    				&.clearance-off-tile{
    					h1{
    						font-size: 55.5px;
    						line-height: 70px;
    					}
    					h2{
    						font-size: 55.5px;
    						line-height: 50px;
    					}
    				}
    				&.last {
    					margin-right: auto;
    				}
		   		}
			}
		}
		
		@media only screen and ( max-width:480px ) {
			.promo-wide {
				h1 {
					letter-spacing: 10px;
					width: 100%;
    				margin: auto;
    				line-height: 40px;
				}
				h2 {
					letter-spacing: 10px;
					line-height: 30px;
					margin: 0 0;
				}
			}
			
			.bottom-banner {
				.text-content {
			    }
			}
			
			.collections {
				h5 {
					font-size: 16px;
				}
				h3 {
					&.heading {
						letter-spacing: 4px;
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}
	
//Trending Now
.div-trending-section {	
	.you-may-like {
		margin: 46px auto 0;
	}
	
	.recommendation-heading {
		margin-bottom: 15px;
		
		span {		
			&.trending {
				font-family: $Hurme;
				font-size: $base-font + 18px;
				width: auto;
				padding-right: 5px;
				
				@media screen and (max-width: 1023px) {
					font-size: $base-font + 13px;
				}
				
				@media screen and (max-width: 767px) {
					font-size: $base-font + 10px;
				}
			}
			
			&.week {
				font-family: $Hurme;
				font-size: $base-font + 18px;
				width: auto;
				
				@media screen and (max-width: 1023px) {
					font-size: $base-font + 13px;
				}
				
				@media screen and (max-width: 767px) {
					font-size: $base-font + 10px;
				}
			}
		}
	}
	
	.carousel-recommendations {		
		button {			
			&.slick-prev {
				background: url(../../../images/trending-arrows-left.svg) no-repeat;
			    
			    &.slick-disabled {
					background: url(../../../images/recently-viewed-arrows-left-unactivated.svg) no-repeat;
				}
			}
			
			&.slick-next {
				background: url(../../../images/trending-arrows-right.svg) no-repeat;
				
				&.slick-disabled {
					background: url(../../../images/recently-viewed-arrows-right-unactivated.svg) no-repeat;
				}
			}
		}
	}
}