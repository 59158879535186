@import "../common/variables";
@import "../common/mixin";
@import "font";
@import "variables";

.pt_customer-service .legacy-sitegen {
	.primary-content {
		max-width: 964px;
		margin: 0 auto;
		float: none;
	}

	.customer-service-landing-faq {	
		.qa-section {
			border-top: none;
			
			.question-title {
				padding: 32px 0 0 0;
				font-family: $HurmeSemiBold;
				letter-spacing: 3px;
				text-transform: uppercase;
			}			
			.qa-desc {
				margin-top: 18px;
			}
			.qa-content {
				padding: 22px 38px 22px 22px;
				background-color: $FB-lightest-gray;
				.question {
					@include WorkSansBold($WorkSans);
					font-size: $base-font + 2px;
					letter-spacing: .5px;
					text-transform: none;
					cursor: pointer;
					&:hover {
						color: $black;
					}
					
					&.active {
						cursor: pointer;
					}
				}
			}
		}
	}
}

.customer-service-landing-contacts {
	margin: 0 0 3% 0;
}

.customerservice-name {
	text-align: center;
	font-size: $base-font + 24px;
    text-transform: uppercase;
    font-family: $HurmeSemiBold;
    letter-spacing: 3px;
    margin: 0 0 25px 0;
}

.tiles-section {
	width: 100%;
	display: inline-block;
	margin: 0 0 10px 0;
}
.contactpage-right-content {
	h1 {
		letter-spacing: .3px;		
	}
}
.content-asset-info {
	float: left;
	background-color: $FB-lightest-gray;
	text-align: center;
	margin: 0 2% 20px 0;	
	
	&:nth-child(3n) {
		margin: 0 0 20px 0;
	}
	
	&:hover {
		background-color: $black;
		.hovered-content {
			display: table;
			//padding: 15px 45px 15px 45px;
			height: 200px;
			width: 67%;
		    vertical-align: middle;
		    margin: 0 auto;
		    
		   a {
		   		display: table-cell;
		    	vertical-align: middle;
		   }
		   
		    .hovered-text {
		    	display: table-cell;
		    	vertical-align: middle;
		    }
		    
			ul {
				font-size: $base-font;
				font-family: $WorkSans;
				letter-spacing: .5px;
				margin: 0;
				padding: 0;
				text-align: left;
				color: $white;
			}
			li {
				margin: 0 0 17px 0;
			}
		}
		.inner-asset-info {
			display: none;
		}
	}
	.hovered-content {
		display: none;
	}
	
	.hovered-title {
		display: none;
	}
	
	.inner-asset-info {
		display: table;
		.customerservice-assest-link {
			.account-shopping-fb {
				img {
					width: 38px;
					margin-bottom: 1px;
				}
			}
			.account-shipping-fb {
				img {
					width: 64px;
				}
			}
			.fb-custservice-returnexchange {
				img {
					width: 40px;
					margin-bottom: 1px;
				}
			}
			.fb-custservice-platinumcards {
				img {
					width: 40px;
				}
			}
			.fb-custservice-giftcards {
				img {
					width: 40px;
					margin-top: -12px;
				}
			}
			.fb-custservice-sizechart {
				img {
					width: 39px;
					margin-bottom: 2px;
				}
			}
		}
	}
	.title {
		font-size: $base-font + 2px;
		font-family: $HurmeSemiBold;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 2px;
   		display: inline-block;
   		width: 100%;
   		margin: 30px 0 0 0;
	}
}

.inner-asset-info {
	//padding: 45px 0 40px 0;
	height: 200px;
    width: 100%;
    display: table;
    
    .icon {
    	width: 108px;
    	height: 72px;
    }
    
    a {
    	vertical-align: middle;
    	display: table-cell;
    	
    	img {
    		max-width: 100%;
    		max-height: 100%;
    	}
    }
}

.inner-service-landing-contacts {
	padding: 23px 0 50px 0;
    text-align: center;
    background-color: $FB-lightest-gray;
    
    h2 {
    	font-size: $base-font + 8px;
	    padding: 10px 0 4px 0;
		font-family: $Hurme;
		font-weight: bold;
	    letter-spacing: 3px;
	    text-transform: uppercase;
	   	width: 100%;
	   	display: inline-block; 
	   	margin-top: 0;
	   	margin-bottom: 28px;
	}
	.call-image {
		img {
			width: 17px;
		}
	}
	.mail-image {
		img {
			width: 24px;
		}
	}
}
.ways-contacts {
	width: 100%;
	display: inline-block;
}

.media-contacts {
    padding: 20px 42px 20px 0;
}

.chat-contact {
	display: inline-block;
	border-left: 1px solid $alto;
    border-right: 1px solid $alto;
	div{
		margin: 20px 31px 20px 31px;
	}
}

.chat-title {
	display: block;
}

.mail-contact {
	padding: 20px 42px 20px 26px;
}

.contact-title,.chat-title,.mail-title {
	font-size: $base-font + 2px;
	@include WorkSansSemiBold($WorkSans);
	letter-spacing: 0;
}

.contact-text {
	font-size: $base-font + 2px;
	@include WorkSansMedium($WorkSans);
	margin: 0 0 0 12px;
}

.chat-text {
	font-size: $base-font + 2px;
	@include WorkSansMedium($WorkSans);

	div {
		display:inline;	
	}
}

.chat-text-available {
	color: $black;
	font-size: 14px;
	padding: 0 5px;
	text-transform: uppercase;
	@include WorkSansSemiBold($WorkSans);
}

.chat-text-unavailable {
	color: #bd030c;
	padding: 0 5px;
	text-transform: uppercase;
}

.mail-text {
	font-size: $base-font + 2px;
	@include WorkSansMedium($WorkSans);
}

.customer-service-faq {
	text-align: left;
	margin-top: 20px;
}

.view-all-faq {
	background: $black;
	color: $white;
	font-family: $HurmeBlack;
	font-size: $base-font + 2px;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 3.5px;
	padding: 0;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	
	a {
		width: 100%;
		color: $white;
		padding: 14px 30px;
		float: left;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		
		&:hover {
			color: $white;
		}
	}
}

.call-image,.chat-image,.mail-image {
	display: inline-block;
	margin: 0 5px 0 0;
	vertical-align: middle;
}

.available-image {
	display: inline-block;
	margin: 0 5px;	
}

.pt_article-page {
	&.cs-faq-ww .legacy-sitegen {
		.customerservice-name {
			padding: 29px 0 46px 12px;
			 box-sizing: border-box;
		}
		
		.article-heading {
			margin: 25px 0 0 0;
		}
	}
	.qa-section {
		.qa-content {
			background-color: $FB-lightest-gray;
		}
	}
}

//Frequently Asked Questions
.faq-content {
	margin: 0 0 30px 0;
	
	h2 {
	    margin: 16px 0 10px 0;
		text-transform: capitalize;
		font-size: $base-font + 8px;
	     @include WorkSansBold($WorkSans);
	     letter-spacing: .3px;
	}
	
	.qa-section {
		.qa-desc {
			.qa-content {
				padding: 23px 39px 20px 29px;
				
				&.active {
					padding: 23px 39px 33px 29px;
				}
				
				.question {
					font-size: $base-font + 2px;
					letter-spacing: 0.5px;
					display: inline-block;
					width: 100%;
					
					span {
						width: 95%;
    					float: left;
					}
					
					&::after {
						background-image: url('../../../images/ww/closed-arrow-FAQ.png');
						background-repeat: no-repeat;
						background-size: 12px;
						background-position: 100% 4px;
					}
					
					&.active {
						margin: 0 0 9px;
						
						&::after {
							background-image: url('../../../images/ww/open-arrow-FAQ.png');
						}
					}
				}
				
				.answer {
					padding: 2px 0 0 0 !important;
				}
			}
		}
	}
}

//Article-pages
.pt_article-page .legacy-sitegen {
	@media screen and (min-width: 1024px) {
		#main {
			padding: 0 20px;
		}

		.primary-content {
			width: 66.7%;
    		float: left;		
		}
	}
	
	#secondary {
		width: 26.3%;
		box-shadow: none;
		padding:0 15px;
		float:left;
		
		nav {
			border-bottom: none;
		}
		
		.secondary-navigation {
			max-width: 154px;
		    margin: 0;
		    text-align: left;
		    
		    ul {
		    	font-size: $base-font - 1px;
		    	@include WorkSansMedium($WorkSans);
		    	letter-spacing: 1px;
		    	margin: 0;
		    	
		    	li {
		    		margin: 0 0 17px 0;
		    		
		    		a {
		    			color: $black;
						@include WorkSansRegular ($WorkSans);
						font-size: $base-font + 3px;
						text-transform: capitalize;
						line-height: 1;
						letter-spacing: 0;
						&:hover {
							text-decoration: underline;
						}
		    		}
		    		
		    		.currentpage {
						text-decoration: underline;
		    		}
		    		
		    		&:hover {
		    			a {
		    				color: $black;
		    			}
		    		}
		    	}
		    }
		}
	}
	
	.customerservice-name {
	    border-bottom: 1px solid $alto;
	    margin: 0;
	    padding: 0 0 44px 0;
    	letter-spacing: 3px;
	}
	
	.qa-section {
		border-top: none;
		.qa-content {
			padding: 22px 39px 22px 25px;
			.question {
				@include WorkSansBold($WorkSans);
				font-size: $base-font + 2px;
				letter-spacing: .5px;
				cursor: pointer;
				
				&::after {
					background: url(../../../images/carat-down.svg) no-repeat;
					background-position: 100% 5px;
				}
			
			     &.active {
			    	cursor: pointer;
			    	
			    	&::after {
			    		background: url(../../../images/carat-up.svg) no-repeat;
    					background-position: 100% 5px;
			    	}
			    }
			}
			.answer {
				font-size: $base-font + 2px;
				p {
					font-size: $base-font + 2px;
					margin: 3px 0 1px 0;
					&:last-child {
						margin: 3px 0 12px 0;
					}
				}
				.sub-heading {
					font-size: $base-font + 2px;
					padding: 10px 0 14px 0;
				}
			}
			&:nth-child(1n) {
				.answer {
					padding: 0;
				}
			}
		}
	}
	
	.account-nav-asset {
		display: none;
	}
	
	// Global elements in article pages
	.make-label-absolute {
		label {
			span {
				@include WorkSansMedium($WorkSans);
	    		font-size: $base-font; 
	    		color: $dim-gray;
			}
		}
	}
	
	input[type="text"], input[type="number"], input[type="password"], input[type="date"],textarea, select {
	    border-radius: 0;
	    @include WorkSansMedium($WorkSans);
		font-size: $base-font; 
		border: 1px solid $silver;
	}
}

//Contact page global elements
.contactpage-right-content {
	.contactpage-chat-module {
		h1 {
			margin: 3px 0 17px;
		}
	}
	.thankyou-section {
		.contactusthankyouforcontacting {
			 @include WorkSansMedium($WorkSans);
		}
	}
}


.contact-us-page {
	.form-wrapper {
		margin-top: 26px;
		padding: 7.3% 6.5% 7.3% 7.5%;
		
		@media screen and (min-width: 768px) {
			.left-cont {
				max-width: 289px;
				width: 39.6%;
			}
			.right-cont {
				max-width: 386px; 
		    	width: 52.3%;
		    	
		    	.form-row-button {
	    		    margin-bottom: 0;
		    	}
			}
		}
		.solid-wine-berry {
			min-width: 180px;
		}
	}
}

.article-heading {
	margin: 26px 0 0 0;
    padding: 0 0 14px 0;
    border-bottom: 1px solid $alto;
    font-size: $base-font + 8px;
	font-family: $Hurme;
	font-weight: bold;
	letter-spacing: 3px;
	text-transform: uppercase;
    .menu-word {
    	display: none;
    }
}

.secondary-article-heading {
	margin: 29px 0 23px 0;
	@include WorkSansBold($WorkSans);
	font-size: $base-font + 3px;
	letter-spacing: 0;
	display: inline-block;
	text-transform: capitalize;
}

.shipping-info-section {
	margin: 20px 0 27px 0;
}

.merchandise-structure {
	width: 66.8%;
	display: inline-block;
	
	.col-1 {
		width: 54%;
		margin: 6px 0 6px 0;
	}
	
	.col-2 {
		width: 38%;
		margin: 6px 0 6px 0;
	}
	
	span {
		width: 100%;
		display: inline-block;
		margin: 0 0 14px 0;
		font-size: $base-font + 2px;
		@include WorkSansMedium($WorkSans);
	} 
}

.shipping-heading {
	margin: 0 0 15px 0;
	font-size: $base-font + 1px;
	@include WorkSansBold($WorkSans);
}

.restriction-bold {
	@include WorkSansBold($WorkSans);
	font-size: $base-font + 1px;
	margin: 10px 0 0 0;
}

.menu-word {
	display: none;
}

//Privacy Policy
.privacy-policy-ww {
	.primary-content {
		width: 100%;
	}
}

.privacy-policy {
	margin: 0 0 20px 0;
	    
	.back-to-top {
		font-size: 12px;
		@include WorkSansBold ($WorkSans);
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	
	a {
		&[href], &.anchor {
			color: $black;
		}
	}
	
	.heading {
	    margin: 0;
		padding: 24px 0 14px 10px;
		border-bottom: 1px solid $alto;
		border-top: 1px solid $alto;
		font-size: 24px;
		 @include WorkSansMedium($WorkSans);
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
 	 	-moz-box-sizing: border-box;
	}
	
	.para-description-section {
		padding: 0 10px;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
 	 	-moz-box-sizing: border-box;
	}
	
	.sub-heading {
		font-size: 14px;
		 @include WorkSansMedium($WorkSans);
		text-transform: none;
		font-weight: normal;
		margin: 16px 3px 17px;
	}
	
	.para-description {
		font-size: 13px;
		 @include WorkSansMedium($WorkSans);
		padding: 0 30px;
		
		p {
			font-size: 13px;
			margin: 30px 0 15px 0 ;
			line-height: 23px;
		}
		
		&.section-1 {
		    padding: 12px 30px 50px;
			background: $light-skygrey;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
	 	 	-moz-box-sizing: border-box;
	 	 	
			.list {
				h2 {
					text-transform: none;
					font-weight: normal;
					margin: 19px 0 0 2px;
					font-size: 13px;
				}
				
				ul {
					margin: 14px 0 0 0;
					padding: 0 0 0 18px;
					
					li {
						margin: 0 0 12px 0;
						@include WorkSansSemiBold($WorkSans);
						color: $black;
						list-style: disc outside none;
						
						a {
							color: $black;
						}
						
						p {
							margin: 10px 25px;
						}
					}
				}
			}
		}
		
		&.section5 {
			.back-to-top {
				margin-top: 15px;
				display: inline-block;
			}
			
			h2 {
				margin: 20px 0;
				font-size: $base-font;
				text-transform: none;
			    width: auto;
			    display: inline-block;
			    font-weight: normal;
			}
		}
		
		h1 {
			margin: 40px 0 0 0;
			font-size: 24px;
			 @include WorkSansMedium($WorkSans);
			text-transform: capitalize;
			padding: 0 0 18px 0;
    		border-bottom: 1px solid $alto;
		}
		
		ul {
			padding: 0;
			margin: 10px 0 15px 0;
			line-height: 22px;
			    
			li {
				list-style: inside disc;
    			margin-bottom: 40px;
    			
    			&:last-child {
    				margin-bottom: 15px;
    			}
			}
		}
	}
	
	.qa-content {
	    padding: 23px 39px 20px 29px;
	    background-color: $light-skygrey;
	    margin: 0 0 2px 0;
	    
	    &.active {
	    	padding: 23px 39px 19px 29px;
	    }
	}
	
	.question {
	    font-size: $base-font + 2px;
	    letter-spacing: .5px;
	    width: 100%;
	    display: inline-block;
	    cursor: pointer;
	    @include WorkSansBold ($WorkSans); 
	    text-transform: uppercase;
	    
	    &::after {
	    	background-image: url(../../../images/dropdown-arrow-down.png);
		    background-repeat: no-repeat;
		    background-size: 15px;
		    background-position: 100% 5px;
	    }
	    
	    &.active {
	    	margin: 0 0 15px;
	    	
	    	&::after {
    			background-image: url(../../../images/dropdown-arrow-up.png);
    		}
	    }
	}
	
	.answer {
		display: none;
		
		&.active {
			display: block;
			font-size: $base-font + 2px;
			@include WorkSansMedium($WorkSans);
			
			div {
				margin: 0 0 15px 0;
			}
			
			.visit-link {
				a {
					border-bottom: 1px solid $black;
				}
			}
			
			.postal-address {
				.label {
				    display: inline-block;
				    width: auto;
				    vertical-align: top;
				}
				
				.value {
					width: auto;
				    display: inline-block;
				    line-height: 20px;
				}
			}
			
			.customer-service {
				.contact {
				    border-right: 1px solid $black;
					padding-right: 10px;
				}
				
				.hearing {
					padding-left: 10px;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.pt_article-page{
		&.privacy-policy-ww .legacy-sitegen {
			.primary-content {
				width: 100%;
			}
		}
	}
	
	.media-contacts {
		padding: 20px 13px 20px 0;
	}
	.chat-contact {
		div {
			margin: 20px 8px 20px 8px;
		}
	}
	.mail-contact {
		padding: 20px 15px 20px 7px;
	}
	.pt_article-page .legacy-sitegen {
		.page-content {
			padding: 0 20px;
		}
	}
	.merchandise-structure {
		width: 80.8%;
		
		.col-1 {
			width: 67%;
			margin: 0;
		}
		
		.col-2 {
			margin: 0;
			width: 33%;
		}
	}
}

@media screen and (max-width:1023px) {
	.pt_customer-service .legacy-sitegen {	
		.page-content {
			padding: 0 20px;
		}
		.qa-section  {
			.question-title {
				float: left;
			    width: auto;
			    display: inline-block;	
			}
		}
		
		.qa-desc {
			width: 100%;
			display: inline-block;
		}
	}
	
	.customer-service-faq {	
		&:last-child {
	   		display: inline-block;
	   		margin: 20px 0 30px 0;
	   	}
	      
	    .view-all-faq {	    
	    	a {
	    		padding: 14px 42.5px;
	    	}
	    }
	}
	.content-asset-info {
		&:hover {
			background-color: $light-skygrey;
			.hovered-content {
				display: none;		
			}
			.inner-asset-info {
				display: table;
			}
		}
	}
	
	.customerservice-name {
		font-size: $base-font + 38px;
		margin: 30px 0 40px 0;
		letter-spacing: 10px;
	}
	
	.content-asset-info {
		.title {
			font-size: $base-font + 4px;
		}	
	}
	
	.media-contacts {
		padding: 20px 8px 16px 0;
	}
	
		
	.inner-service-landing-contacts {
		padding: 30px 0 40px 0;
	}
	
	.mail-contact {
		padding: 10px;
	}
	
	//Article-page
	.pt_article-page .legacy-sitegen {
		.second-section {		
			width: 100%;
			display: inline-block;	
		}
		#secondary {
			width: 49.5%;
			margin: 15px 20px 14px;
			
			.secondary-left-section {
				border: 3px solid $black;
				border-top: none;
				width: 100%;
				display: none;
				background-color: $light-skygrey;
				&.active {
					display: block;
					z-index: 10;
    				position: absolute;
    				box-sizing: border-box;
				}
				ul {
					@include WorkSansSemiBold ($WorkSans);
					font-size: $base-font + 6px;	
				}
			}
			
			.secondary-navigation {
				max-width: 100%;
				background-color: $light-skygrey;
				position: relative;
				
				.secondary-aticle-content {
					width: 100%;
					display: inline-block;
					border: 3px solid $black;
					background-color: $light-skygrey;
					box-sizing: border-box;
					&.activee {
						padding: 0 0 3px 0;
						border-bottom: none;
					}
				}
				
				ul {
					li {
						margin: 4px 20px 21px 20px;
						.currentpage {
							font-family: $Hurme;
			    			color: 	$black;
			    		}
					}
				}
			}
		}
		.customerservice-name{
			display: inline-block;
    		width: 100%;
    		padding: 30px 0 30px 0;
    		border-top: 1px solid $alto;
    		letter-spacing: 10px;
    		&.hide-tablet {
    			display: none	
    		}
    		&.hide-mobile {
    			display: none;
    		}
		}
		
		.page-content {
			padding: 0 20px;
			box-sizing: border-box;
		}
	}
	
	.secondary-article-heading {
		margin: 12px 18px;
		@include WorkSansSemiBold ($WorkSans);
		font-size: $base-font + 6px;
		background: url(../../../images/arrow-down.png) no-repeat;
		background-position: 100% 7px;
	    background-size: 15px;
	    text-transform: capitalize;
	    display: block;
	    
	    &.active {
	    	background: url(../../../images/arrow-up.png) no-repeat;
			background-position: 100% 7px;
		    background-size: 15px;	
	    }
	}
	
	.opacity-coloring {
		background-color: $white;
		opacity: 0.5;
	}
	
	.merchandise-structure {
		width: 100%;
		
		.col-1 {
			width: 46%;
		}
		
		.col-2 {
			width: 45%;
		}
	}
	.menu-word {
		display: inline;
	}
	
	.pt_article-page {
		&.cs-faq-ww .legacy-sitegen {
			.customerservice-name {
				padding: 29px 0 15px 12px;
				border-bottom: 0;
    			letter-spacing: 12px;
			}
			
			.article-heading {
				padding-left: 18px;
			}
		}
	}
	
	//Frequently Asked Questions
	.faq-content {
		.qa-section {
			.qa-desc {
				.qa-content {
					padding: 23px 29px 20px 29px;
					
					&.active {
						padding: 23px 29px 17px 29px;
					}
				}
			}
		}
	}
	
	//Privacy Policy
	.privacy-policy {
		.heading {
			padding: 16px 0 14px 18px;
		}
		
		.para-description {
			padding: 0;
		}

		.para-description-section {
			padding: 0;
		}
		
		.para-description {
			&.section-1 {
				margin: 20px 0 0 0;
				padding: 12px 30px 20px;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_customer-service .legacy-sitegen {
		.page-content {
			padding: 0 10px;
		}
		
		.qa-section {
			.question-title {
				padding-right: 0;
				letter-spacing: 3px;
				font-size: $base-font + 8px;	
			}
			
			.qa-content {
				padding: 20px 10px 20px 10px;
			}
		}
	}
	
	.customerservice-name {
		font-size: $base-font + 12px;
		margin: 30px 0 16px 0;
	}
	.content-asset-info {
		width: 100%;
		margin: 0 0 5px 0;
		
		&:nth-child(3n) {
			margin: 0 0 5px 0;
		}
		
		.inner-asset-info {
			padding: 0;
			display: inline-block;
			height: auto;
			
			a {
				width: 85%;
				display: table;
				vertical-align: middle;
				margin: 0 auto;
				margin-top: 15px;
    			margin-bottom: 15px;
			}
		}
		
		.icon {
			float: none;
			display: table-cell;
			vertical-align: middle;
			width: 35.5px;
    		height: 25px;
		}
		
		.title {
			width: auto;
			float: none;
			display: table-cell;
			vertical-align: middle;
			text-align: left;
    		padding: 0 0 0 32px;
		}
	}
	.inner-service-landing-contacts {
		h2 {
			padding: 12px 0 4px 0;
			font-size: $base-font + 9px;
		}
	}
	.media-contacts {
		width: 100%;
	    display: inline-block;
	    box-sizing: border-box;
	    padding: 20px 0 20px 0;
	    border-right: none;
	}
	
	.call-image {
		width: 100%;
		margin: 0 0 10px 0;	
	}
	
	.mail-image {
		width: 100%;
		margin: 0 0 10px 0;
	}
	
	.chat-image {
		width: 100%;
	}
	
	.contact-title {
	    display: inline-block;
	    padding: 0 0 5px 0;
	}
	
	.contact-text {
		margin: 0;
		display: inline;
		@include WorkSansSemiBold($WorkSans);
	}
	
	.chat-text {
		@include WorkSansSemiBold($WorkSans);
	}
	
	.mail-text {
		@include WorkSansSemiBold($WorkSans);
	}
	
	.chat-contact {
		width: 100%;
	    display: inline-block;
	    box-sizing: border-box;
	    border-right: none;
	}
	
	.mail-contact {
		width: 100%;
	    display: inline-block;
	    box-sizing: border-box;
	    padding: 20px 0 0 0;
	}
	
	.customer-service-faq {
		&:last-child {
		    margin: 20px 0;
		}
	}
	
	.tiles-section {
		margin: 0 0 20px 0;
	}
	
	//Article-page
	.secondary-article-heading {
		font-size: $base-font + 4px;
	}
	
	.pt_article-page .legacy-sitegen {
		.page-content {
			padding: 0 20px;	
		}
		
		.primary-content {
			display: inline-block;
		}
		#secondary {
			width: 100%;
    		margin: 15px 0;
    		
    		nav {
    			margin: 0;
    		}
			.secondary-navigation {
				ul {
					font-size: $base-font + 4px;
				}
			}
		}
		.customerservice-name {
			display: none;
		}
		
		.qa-section {
			.qa-content {
				padding: 25px 15px;	
			}
		}
		.col-1 {
			width: 30%;
		    float: left;
		    margin: 0 9% 0 0;
		}
		
		.col-2 {
			width: 21%;
    		float: left;
    		margin: 0;
		}
		
		.qa-section {
			.qa-content {
				.answer {
					font-size: $base-font + 2px;
					.sub-heading {
						font-size: $base-font + 2px;	
					}
					
					p {
						font-size: $base-font +2px;
					}
				}
				
				.question {
					font-size: $base-font + 2px;
				} 
			}
		}
		.mail-contact {
			display: block;
		}
	}
	
	.merchandise-structure {
		font-size: $base-font;
		
		span {
			font-size: $base-font;		
		}
	}
	
	.restriction-bold {
		font-size: $base-font;
	}
	
	.article-heading {
		margin: 0;
		font-size: $base-font + 9px;
	}
	
	.shipping-heading {
		font-size: $base-font;
	} 
	
	.contact-us-page .form-wrapper {
	    padding: 18px 10px 53px;
	}
	
	.pt_article-page {
		&.cs-faq-ww .legacy-sitegen {
			.article-heading {
				margin: 10px 0 0 0;
				padding: 0 0 14px 0;
			}
		}
	}
	
	//Frequently Asked Questions
	.faq-content {
		.qa-section {
			.qa-desc {
				.qa-content {
					padding: 20px 10px;
					
					&.active {
						padding: 20px 10px;
					}
				}
			}
		}
	}
	
	//Privacy Policy
	.privacy-policy {
		.heading {
			padding: 24px 0 14px;
		}
		
		.sub-heading {
			font-size: 12px;
		}
		
		.para-description {
			p {
				font-size: 13px;
			}
			
			&.section-1 {
			    padding: 12px 15px 20px;
		 	 	
		 	 	p {
		 	 		font-size: 12px;
		 	 		margin: 0;
		 	 	}
		 	 	
				.list {
					h2 {
						font-size: 12px;
					}
					
					ul {
						padding: 0 0 0 15px;
					}
				}
			}
			
			h1 {
				font-size: 21px;
			}
			
			ul {
				margin: 10px 0 15px 0;
				
				li {
					margin-bottom: 20px;
				}
			}
			
			.qa-content {
			    padding: 20px 10px;
			    
			    &.active {
			    	padding: 20px 10px;
			    }
			}
			
			.answer {
				&.active {
					font-size: $base-font + 2px;
					
					.customer-service {
						.contact {
						    border-right: 0;
							padding-right: 0;
						}
						
						.hearing {
							padding-left: 0;
							margin: 10px 0 0 0;
							width: 100%;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.pt_article-page .legacy-sitegen {
		.page-content {
			padding: 0 10px;
		}
		
		.qa-section {
			.qa-content {
				padding: 25px 13px;
			}
		}
		.col-1 {
			width: 50%;
	    	margin: 0;
		}
		
		.col-2 {
			width: 45%;
		}
	}
	.merchandise-structure {
		width: 100%;
	}
}




