@media print {
	// Header
	header {
		.top-menu-utility, .top-banner {
			background-color: transparent;
		}	
		.primary-logo svg {
			width: 226px;
			margin-top: -4rem;
			
			path {
				fill: $black;
			}
		}
	}
}
