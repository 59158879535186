.search-result-options {
    .pagination {
        li {            
		    @include WorkSansSemiBold($WorkSans);
			color:  #a2a2a2;
			font-size: $base-font + 2px;
			border: 1px solid #a2a2a2;
			a {
				color: $blue-haze;
			}
        }
    }
}

.search-result-items {
	.grid-tile {
	    margin: 0 auto 35px;
	    border: 1px solid transparent;	   
	    .product-tile {
	    	&:hover {
	    		box-shadow: $dark-gray 0px 0px 7px;
	    	}
    	}
	}
}

.view-more {
    .viewmore-grid {
    	font-size: $base-font + 2px;
    	@include WorkSansSemiBold($WorkSans);
    }
}

.product-search-page {
	.search-result-content {
		.grid-tile.product-brylane-category {
			width : 20%;
			margin-right: 0;
			&:nth-child(5n+1) {
				clear: both;
			}
						
			@media screen and (max-width: 1320px) {
				width : 33.33%;
				margin-right: 0;
				&:nth-child(5n+1) {
					clear: none;
				}
				&:nth-child(4n+1) {
					clear: both;
				}
			}
			
			@media screen and (max-width: 1023px) {
				width : 33.33%;
				margin-right: 0;
				&:nth-child(5n+1) {
					clear: none;
				}
				&:nth-child(4n+1) {
					clear: none;
				}
				&:nth-child(3n+1) {
					clear: both;
				}
			}

			@media screen and (max-width: 767px) {
				width: 50%;
				margin-right: 20px;
				margin-right: 0;
				&:nth-child(5n+1) {
					clear: none;
				}
				&:nth-child(4n+1) {
					clear: none;
				}
				&:nth-child(3n+1) {
					clear: none;
				}
				&:nth-child(odd) {
					clear: both;
				}
			}
		}
	}
}

.search-result-items {
    .grid-tile.product-brylane-category {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;        
        width: 33.33%;
        float: left;
        display: inline-block;
        vertical-align: top;
        padding: 0 0 10px 20px;

       @media screen and (min-width:768px) and (max-width:1023px){
			 padding-left:0;
 		}
		@media screen and (max-width: 767px){
		width:50%;
			padding:0 0 30px 0;
		}  
        .product-tile {
            &:hover {
                background: $white;
                z-index: 1;
            }
            &.product-set {
                .quickview {
                    display: none !important;
                }
            }
        }
    }	
}