
@import "../common/variables";
@import "../common/mixin";
@import "font";
@import "variables";
@import "lib/flag-icon-css/flag-icon";
//Common scss import
//@import "../common/common";
// lib
.legacy-sitegen {
    max-width: 1260px;
    margin: 0 auto;
@import "lib/normalize";
@import "lib/slick";
@import "base_elements";

@import "jqueryui";
@import "form_components";
@import "product_lists";
@import "base_tooltip";
@import "header/header";
@import "minicart";
@import "add_to_cart";
@import "breadcrumbs";
@import "catalog-quick-order";
@import "secondary_region_elements";
@import "footer";
@import "color_swatches";
@import "product_tiles";
@import "search_results_grid_layout";
@import "cart";
@import "checkout";

@import "gift_certificates";
@import "login_pages";
@import "category_landing_page";
@import "mixandmatch";
@import "search_suggestion";
@import "homepage";
@import "multi_inventory";
@import "print";
@import "no_results";
@import "search_results";
@import "gift_landing";
@import "pdp_personalization";
@import "custom_select";

@import "customer_service";
@import "plcc_checkout";
@import "plcc_checkout_B";
@import "return_items";
@import "catalog_preference";
@import "lookbooks";
@import "plcc_landing";
@import "error_pages";
@import "certona-offers-coupons";
@import "turnto_override";
@import "certona";
@import "email_sub_confirmation";
@import "pdp_B";
@import "responsive";
}
@import "page_layouts";
@import "account";
@import "size_charts";
@import "responsive";
@import "product_detail_page";
@import "cart_overlay";
@import "account_overview";
@import "orders";
@import "wishlist";
@import "gift_landing";
@import "bonus_products";

.design-systems{
    @import "node_modules/fbb-design-systems/src/scss/custom-brand-settings/av";
    @import "../common/design-systems";
}

