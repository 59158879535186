.search-suggestion-wrapper {
	z-index: 23;
	padding: 0;
    
	.suggested-phrase {
		font-size: $base-font + 4px;
		color:$black;		
	}
	
	@media screen and (min-width: 1024px) {
		&.full {			
			.product-suggestions {
				padding-left: 15px;
			}
		}
	}
	
	@media screen and (max-width: 1023px) {
		z-index: 23;
		padding-top: 0;
	}
	
	.hitgroup {

		.hit {
			margin-left: 10px;
			@include WorkSansRegular($WorkSans);
			font-size: $base-font + 1px;
			letter-spacing: 0.5px;
			color: $dim-gray;
			.searched-key {
				@include WorkSansBold($WorkSans);
				font-size: $base-font + 1px;
				letter-spacing: 0.5px;
				color: $black;
			}
		}

		.parent-category {
			@include WorkSansRegular($WorkSans);
			font-size: $base-font + 1px;
			letter-spacing: 0.5px;
		}
	}
}

.product-suggestion {
	padding: 5px 0 4px;
	border-bottom: 1px solid $FB-mercury-light;
	border-left: 4px solid transparent;
	
	&:hover, &:focus {
		background-color: $white;
	    border-left: 4px solid $black;
	    border-right: none;
	    border-top: none;
	}
	
	.product-image {
		img {
			float:left;
		}
	}

	.product-details {

		.product-name {
			font-size: $base-font + 1px;
		    color: $black;
		    font-family: $HurmeSemiBold;
		    letter-spacing: 0.5px;
		    text-transform: uppercase;
		}

		.product-price {
		    font-size: $base-font + 1px;
		    @include WorkSansSemiBold($WorkSans);
		    letter-spacing: normal;
		    
			span {
				&.subunit {
				    left: -4px;
				}
			}
		}
	}
}

.search-phrase {
	font-size: $base-font + 2px;
    @include WorkSansRegular($WorkSans);
    letter-spacing: 0.5px;
    border-bottom: 1px solid $FB-mercury-light;

    a {
    	span {
    		@include WorkSansBold($WorkSans);
    	}
    }

	.completed {
		font-style: normal;
		color: $dim-gray;
		@include WorkSansRegular($WorkSans);
		
		.original {
			font-weight: normal;
			@include WorkSansBold($WorkSans);
		}
	}
}