//Brand-logo-utility
.top-menu-utility{
	 .menu-utility{   
    	.menu-utility-user{
    		.info{
			    &.customer-service-info{
			    	font-family: "Work Sans";
			    }
			    span{
				    font-family: $HelveticaMedium;
			    }
			    &.user-info{
			    	span{
			    		&.username{
						    @include WorkSansMedium($WorkSans);
			    		}		    		
			    	}
			    	.user-panel {
						&.registered-user{
						    #profilemenu{
    							.account-links{
    								a{
									    @include WorkSansMedium($WorkSans);
    								}
    							}
						    }
						    .not-firstname{
						    	@include WorkSansMedium($WorkSans);
						    }
						    .signin-someone{
						    	@include WorkSansMedium($WorkSans);
						    	color: $black;
						    }
						    .account-logout{
						    	a{
								    @include WorkSansMedium($WorkSans);
								    &:hover{
								    	color: $black;
								    }
						    	}
						    }
						}

						.login-box{
							.form-row{
								label{
								    span{
								    	@include WorkSansMedium($WorkSans);
								    }
								}

								.field-wrapper{
									@include WorkSansMedium($WorkSans);

									button{
										&.pwd-show{
									
										    @include WorkSansSemiBold($WorkSans);
										   
										}
									}
								}
								&.form-row-button{
									.login-rememberme { 
										label {
										    .label-text {
											     @include WorkSansRegular($WorkSans);
										    }
										}
									}
								}

								button{
									font-family: $HurmeBlack;
									background-color: $black;
								}
							}

							#password-reset{
							    @include WorkSansSemiBold($WorkSans);
							}
					    }

					    .login-data{
						    span{
							    @include WorkSansBold($WorkSans);
						    }
					    }

					    .login-oauth{
					    	button{
    							color: $white;
							    @include WorkSansSemiBold($WorkSans);
							    background-color: $chambray;
					    	}
					    }

					    .create-account-section{
						    span{
						    	@include WorkSansMedium($WorkSans);
						    }
						    a{
						    	span{
								    @include WorkSansSemiBold($WorkSans);
						    	}
						    }
					    }
					    
					    .account-section-summary {
					    	@include WorkSansMedium($WorkSans);

					    	.signin-someone {
					    		color: $black;
					    	}
					    }
					}

			    }
			    .customer-service-flyout{
				   	.primary{
						.email-us-icon, .livechat-icon{

		    				a {
		    					font-family: $WorkSans;
		    				}
						}
					}

					.cs-flyout-phone{
						span{
							font-family: $WorkSans;
						}
					}

					.cs-flyout-email {
						span {
							font-family: $WorkSans !important;
						}
					}

					.secondary{
						.quick-links{
							.links{
							    font-family: $HurmeSemiBold;
							}
							a{
								@include WorkSansMedium($WorkSans);
							}
						}
					}
			    }
		    }
    	}
    }
}
