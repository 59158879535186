.checkout-body.plcc-redesign {
.ui-dialog {
	&.checkout-plcc-modal,
	&.checkout-plcc-modal-step2,
	&.plcc-acquisition-rebuttal-model,
	&.checkout-plcc-modal-terms,
	&.checkout-plcc-modal-step3 {
		.plcc-terms-banner {
			color: $black;
		}

		.plcc-offer-banner-right {
			color: $black;

			.plcc-see-details .plcc-terms-open {
				color: $black;
			}
		}

		.plcc-application-banner .plcc-banner-details {
			color: $black;

			.plcc-terms-open {
				color: $black;
			}
		}
	}
}
}