.checkout-body:not(.plcc-redesign), #wrapper.pt_specials ~ .plcc-apply-error-model  {
//PLCC Step1
.ui-dialog {
	&.checkout-plcc-modal, &.checkout-plcc-modal-step2, &.plcc-acquisition-rebuttal-model {
		.ui-dialog-title {
		    margin: 21px 0 0 0;
		    font-size: $base-font + 2px;
		    @include WorkSansSemiBold ($WorkSans);
		    letter-spacing: 0.7px;
		} 
	} 
}

.checkout-plcc-modal {
	.checkout-stepone-scroll {
		overflow: auto;
	}
	.plcc-section-1 {
		padding: 0 40px;;
	 	margin-top: 11px;
	 	
	 	.plcc-banner {
		    background: url('../../../images/ww/banner-background.png') 0 0 no-repeat;
		 	height: 229px;
	 	}
	 	
	 	.plcc-banner-main {
	 		max-width: 435px;
	    	color: $white;
	 	}
	 	
	 	.plcc-pre-approved-name {
		    font-size: $base-font + 16px;
		    margin: 21px 0 0 0;
		     @include WorkSansMedium($WorkSans);
	 	}
	 	
	 	.credit-card {
	 		font-size: $base-font + 4px;
	 		 @include WorkSansMedium($WorkSans);
	 		line-height: 20px;
	 	}
	 	
	 	.bonus-reward {
		    margin: 12px 0 7px;
		        
		    h1 {
		    	font-size: $base-font + 18px;
		    	@include WorkSansBold ($WorkSans);
		    	letter-spacing: 8px;
	    		margin: 0;
    			word-spacing: 2.5px;
		    }
		    
		    h2 {
		    	font-size: $base-font + 2px;
		    	 @include WorkSansMedium($WorkSans);
		    }
		    
		    ul {
	    		li {
	    			width: 33.33%;
	    			margin: 28px 0 46px;
	    			
	    			&.earn-rewards {
	    				width: 30%;
	    				
	    				.section {
	    					max-width: 250px;
	    				}
	    			}
	    			
	    			&.offer {
	    				width: 43%;
	    				
	    				.section {
	    					max-width: 135px;
	    				}
	    			}
	    			
	    			&.free-shipping {
	    				width: 24%;
	    				
	    				.section {
	    					max-width: 140px;
	    				}
	    			}
	    			
	    			.section {
	    				max-width: 200px;
	    			}
	    			
	    			span {
	    				font-size: $base-font + 8px;
	    				 @include WorkSansMedium($WorkSans);
	    				margin: 25px 0 0 0;
	    				line-height: 25px;
	    			}
	    			
	    			.image-section {
	    				height: 84px;	
	    			}
	    			
	    			.free-shipping {
	    				img {
	    					padding: 15px 0 0 0;
	    				}
	    			}
	    		}
		    }
	 	}
	}
	
	.pre-screen-notice {
		padding: 0 40px;
	 	font-size: $base-font + 2px;
	 	@include WorkSansSemiBold ($WorkSans);
	 	
	 	span {
	 		border-bottom: 2px solid $black;
	 		margin: 0 0 2px 0;
	 	}
	 	
	 	.prescreen-message {
		    padding: 22px 22px 24px 32px;
			border: 2px solid $black;
			line-height: 21px;
	 	}
	}
	
	.legal-copy {
		padding: 0 42px;
	 	margin: 23px 0 0 0;
	 	font-size: $base-font + 2px;
	 	 @include WorkSansMedium($WorkSans);
	 	color: $dim-gray;
	 	line-height: 21px;
	 	
	 	span {
	 		margin: 16px 0 37px;
	 	}
	 	
	 	a {
	 		border-bottom: 1px solid $black;
	    	color: $black;
	 	}
	}
	
	.form-row-button {
		bottom: 0;
	    width: 100%;
	    overflow: hidden;
	    border-top: none;
	    
	    .form-row-button-fixed {
		    background: $white;
			padding: 20px 0;
			border-top: 1px solid $alto;
	    }
	    
	    button {
	    	margin: 0 0 0 42px;
	    	font-size: $base-font + 2px;
	    	@include WorkSansSemiBold ($WorkSans);
	    	background: $black;
	    	text-transform: uppercase;
	    	
	    	&.plcc-nothanks-button {
	    		border: 2px solid $black;
	    		color: $black;
	    		padding: 15px 42px;
	    	}
	    	
	    	&.plcc-get-it-button {
	    		padding: 17px 36px;
	    		margin: 0 40px 0 0;
	    	}
	    }
	}
}

//PLCC Step2 Dialog
.plcc-model2 {
	.plcc-upper {
		padding: 6px 41px 0 41px;
	}
	
	.plcc-model2-form {
		padding: 36px 20px 14px 28px;
		.plcc-form-row-1{
			.ssn{
				width: 68%;
				float: right;
			}	
			.plcc-masked-number{
				width: 32%;
			    background: #FFFFFF;
			    display: inline-block;
			    padding: 17px 15px;
			    box-sizing: border-box;
			    -moz-box-sizing: border-box;
			    -webkit-box-sizing: border-box;
			    font-family: $WorkSans;
			    font-size: 14px;
			    color: #000000;
			    margin-bottom: 18px;
			}
		}
	}
	
	.pre-approved-copy {
		font-size: $base-font + 2px;
		 @include WorkSansMedium($WorkSans);
		margin: 0 0 20px 0;
		line-height: 20px;
		color: $dim-gray;
	}
	
	.legal-copy {
		font-size: $base-font + 2px;
		margin: 0 0 25px 0;	
		color: $dim-gray;
		line-height: 19px;
	}
	
	.click-here {
		margin: 0 0 15px 0;
	}
	
	.accept-offer-copy {
		font-size: $base-font + 2px;
	    margin: 0 0 15px 0;
	    line-height: 38px;
	    color: $dim-gray;
	}
	
	.plcc-birth-label {
		@include WorkSansMedium($WorkSans);
		font-size: $base-font;
		margin: 0 0 16px 0;
    	letter-spacing: 0.5px;
    	.question-mark-icon{
			clear: both;
		}
	}
	
	.contact-disclaimer {
		font-size: $base-font + 2px;
		margin: 0 0 28px 0;
    	letter-spacing: 1px;
    	line-height: 19px;
    	color: $dim-gray;
	}

	.content-rule-disclamer {
		font-size: 12px;
		line-height: 15px;
	}
	
	.pre-screen-notice {
		font-size: $base-font + 2px;
		text-align: center;
		padding: 26px 23px 27px 23px;
		border: 2px solid $black;
		@include WorkSansSemiBold($WorkSans);
		margin: 0 0 17px 0;
		line-height: 19px;
	}
	
	.plcc-nothanks-button {
		background: $white;
    	border-color: $black;
		span {
			color: $black;
			border-color: $black;
			background-color: $white;
		}
	}
	
	.plcc-personal-header {
		font-size: $base-font;
		@include WorkSansMedium($WorkSans);
		margin: 0 0 17px 0;
		text-transform: uppercase;
		letter-spacing: 0.5px;
	}
	
	.plcc-profile-summary {
		display: inline-block;
		
		.plcc-profile-details {
			@include WorkSansMedium($WorkSans);
			font-size: $base-font;
			color: $dim-gray;
			text-transform: capitalize;
			margin: 0 0 13px 0;
			
			&.plcc-profile-email {
				margin: 0 0 17px 0;
				display: inline-block;
			}
		}
		
		.plcc-edit-profile {
			font-size: $base-font;
			@include WorkSansMedium($WorkSans);
			text-decoration: underline;
			text-transform: capitalize;
			cursor: pointer;
		}
	}
	
	.credit-card-disclaimer {
		margin-bottom: 27px;
	}
	
	&.plcc-main {
		/*
		 commenting below since is breaking the image
		.question-mark-icon {
			width: 28px;
		}
		*/
		.form-row {
			.required-indicator {
				display: none;
			}
			&.form-indent {
				margin-left: 0;
				label {
					@include WorkSansMedium($WorkSans);
					font-size: $base-font + 2px;
					color: $black;
					float: left;
					width: 90%;
					@media screen and (max-width: 767px) {
						width: 88%;
					}
					
				}
			}				
		}
		.plcc-primary {
			.form-row {
				&.month {
    				margin: 0 15px 0 0;	
    				.field-wrapper {
						margin-bottom: 15px;
					}
				}
				&.day {
    				margin: 0 15px 0 0;
    				.field-wrapper {
						margin-bottom: 15px;
					}
				}
				&.year {
					width: 30%;
					.field-wrapper {
						margin-bottom: 15px;
					}
				}
				&.month, &.day, &.year {
					.custom-select {
						.selected-option {
							&:before {
								right: 5px;
							}
						}       
					}
				}
			}
			
			.plcc-ssn-label {
				margin: 0 0 13px 0;
    			letter-spacing: 0.5px;
			}
			
			.ssn {
				.field-wrapper {
					background-color: $white;
					margin-bottom: 13px;
				}
				.input-text {
					width: 100%;
					float: right;
				}
				label {
					&.input-focus {
						font-size: $base-font + 2px;
						
						.input-text {
							padding: 16px 15px 16px 15px;
						}
					}
				}
			}
			
			.phone-number-label {
				letter-spacing: 0.5px;
				margin: 0 0 13px 0;
				.field-wrapper {
					margin-bottom: 19px;
				}
			}
			
			label {
				@include WorkSansMedium($WorkSans);
				font-size: $base-font + 2px;
				
				&.input-focus {
					font-size: $base-font + 2px;
				}
			}
			.important-info {
				font-size: $base-font + 2px;
				line-height: 20px;
				
				.field-wrapper {
					margin-bottom: 30px;
				}
				
				.sign-in {
					margin: 0 0 25px 0;
				}
				
				.ca-resident {
					margin: 0 0 27px 0;
				}
			}
			
			.show-profile {
				.zipcode {
					input[type="text"] {
						width: 100%;
					}
				}
			}
		}
	}	
}

//PLCC Apply Model
.plcc-apply-model {
	width: 65%;
	margin-bottom: 19%;
    .header {
    	font-size: $base-font + 12px;
    	 @include WorkSansMedium($WorkSans);
    	margin: 16.2% 0 3% 0;
    }
    
    .sub-header {
    	font-size: $base-font + 2px;
    	color: $dim-gray;
    	margin: 0 0 12% 0;
    }
    
    button {
    	padding: 15px 35px;
    }
}

.plcc-apply-error {
	width: 69%;
	.header {
		margin: 15.6% 0 2% 0;
		color: $Monza;
	}
	
	.sub-header {
		margin: 0 0 7% 0;
		line-height: 21px;
	}
	
	.try-again {
		padding: 15px 56px;
	}
}

.approved-model {
	 margin-bottom: 16%;
	.header {
		font-size: $base-font + 12px;
		 @include WorkSansMedium($WorkSans);
		line-height: 24px;
    	margin: 4.1% 0 2% 0;
	}
	
	.sub-header {
		font-size: $base-font + 2px;
    	 @include WorkSansMedium($WorkSans);
    	width: 69%;
	    line-height: 22px;
	    margin-bottom: 6.4%;
	    color: $dim-gray;
	}
	
	.continue-message {
		margin: 0 0 7% 0;
		 @include WorkSansMedium($WorkSans);
		font-size: $base-font + 2px;
		color: $dim-gray;
	}
	
	.approved-buttons {
		width: 100%;
    	margin: 0 auto;
    	.guest-sign-in {
    		padding: 15.5px 27px;
    		margin: 0 20px;
    		border-width: 0;
    		letter-spacing: 1px;
    	}
    	.continue-shopping {
    		padding: 15.5px 43px;
    		border-width: 0;
    		letter-spacing: 1px;
    	}
	}
}

//PLCC Acquisition Rebuttal Model
.plcc-rebuttal-overlay {
	.rebuttal-banner-text {
		.welcome {
			color: $white;
			font-size: $base-font + 4px;
			 @include WorkSansMedium($WorkSans);
			margin: 0 0 22px 0;
    		line-height: 24px;
		}
		
		.pre-approved {
			color: $white;
			font-size: $base-font + 4px;
			 @include WorkSansMedium($WorkSans);
			width: 95%;
		    margin: 0 auto;
		    margin-bottom: 15px;
		    line-height: 23px;
		    display: inline-block;
		}
		
		.accept {
			color: $white;
			font-size: $base-font + 4px;
			 @include WorkSansMedium($WorkSans);
			line-height: 33px;
    		margin: 0 0px 11px 11px;
		}
		
		.extra {
			@include WorkSansBold($WorkSans);
			font-size: $base-font + 17.3px;
			color: $white;
			text-transform: uppercase;
		    width: 72%;
		    margin: 0 auto;
		    margin-bottom: 0;
		    letter-spacing: 6px;
		}
		
		.plus {
			@include WorkSansBold($WorkSans);
			font-size: $base-font + 17.3px;
			margin: 0 0 13px 0;
			text-transform: uppercase;
    		letter-spacing: 2px;
    		color: $white;
		}
		
		.bonus {
			@include WorkSansBold($WorkSans);
			font-size: $base-font + 17.3px;
			margin: 0 0 11px 0;
			color: $white;
			text-transform: uppercase;
		    letter-spacing: 8px;
		    line-height: 37px;
		}
		
		.billing {
			color: white;
			font-size: $base-font + 4px;
			 @include WorkSansMedium($WorkSans);
			margin: 0 0 0 14px;
		}
	}
	.pre-screen-notice {
		margin: 19px 0 14px 0;
		border: 2px solid black;
		font-size: $base-font + 2px;
		@include WorkSansSemiBold($WorkSans);
	    padding: 23px 30px 26px 33px;
	    text-align: center;
	    line-height: 20px;
	    .underlined {
	    	text-decoration: underline;
	    }
	}
	
	.legal-copy {
		font-size: $base-font + 2px;
	    color: $dim-gray;
	    padding: 0 12px 0 0;
	    line-height: 20px;
	    
	    span {
	    	margin: 20px 0 37px 0;
	    	line-height: 20px;
	    	display: inline-block;
	    	
	    	a {
	    		text-decoration: underline;
	    	}
	    }
	}
	
	.form-row-button {
		margin: 20px 40px 23px 40px;
		.plcc-nothanks-button {
			float: left;
		    background-color: $white;
		    color: $black;
		    border-color: $black;
		    padding: 15px 41px;
		}
		
		.plcc-get-it-button {
			float: right;
			padding: 15px 31px;
		}
	}
}

@media screen and (max-width: 1023px) {
	//PLCC Step1
	.ui-dialog {
		&.checkout-plcc-modal {
			.ui-dialog-title {
			    letter-spacing: 0;
			} 
		} 
	}
	
	.checkout-plcc-modal {
		.plcc-section-1 {
			padding: 0 40px;
		 	margin-top: 11px;
		 	
		 	.bonus-reward {
			    width: 100%;
			    display: inline-block;
			    margin: 13px 0 7px;
			    text-align: center;
			        
			    h2 {
			    	margin: 1px 0 0 0;
			    }
			    
			    ul {
		    		li {
		    			span {
		    				margin: 0;
    						line-height: 30px;
		    			}
		    		}
			    }
		 	}
		}
		
		.pre-screen-notice {
		 	.prescreen-message {
			    padding: 24px 6px 22px 39px;
		 	}
		}
		
		.legal-copy {
		 	margin: 22px 0 0 0;
		}
	}
}

@media screen and (max-width: 767px) {
	.plcc-model2 {
		.pre-approved-copy {
			font-size: $base-font;
			color: $dim-gray;
			margin: 0 0 15px 0;
		}
		
		.legal-copy {
			font-size: $base-font;
			color: $dim-gray;
			margin: 0 0 15px 0;
		}
		
		.accept-offer-copy {
			font-size: $base-font;
			color: $dim-gray;
		}
		
		.contact-disclaimer {
			font-size: $base-font;
			color: $dim-gray;
		}
		
		.pre-screen-notice {
			font-size: $base-font;
    		padding: 18px 6px;
		}
		
		.plcc-lower {
			padding: 10px 0;
			.plcc-accept-button,.plcc-nothanks-button {
				font-size: $base-font;
			}
		}
		
		&.plcc-main {
			.plcc-upper {
				padding: 0 10px;
			}
			
			.plcc-model2-form {
				padding: 25px 10px 10px 10px;
			}
			
			.plcc-primary {
				.important-info {
					font-size: $base-font;
				}
				.form-row {
					&.month {
						width: 30%;
						margin: 0 12px 0 0;
					}
					&.day {
						width: 30%;
						margin: 0 12px 0 0;
					}
					&.year {
						width: 31%;	
					}
				}
			}
		}
	}
	
	//PLCC Step1
	.ui-dialog {
		&.checkout-plcc-modal, &.checkout-plcc-modal-step2, &.plcc-acquisition-rebuttal-model {
			.ui-dialog-content {
				padding: 0;
			}
			
			.ui-dialog-title {
			    width: 86%;
			} 
		} 
	}
	
	.checkout-plcc-modal {
		.plcc-section-1 {
			padding: 0 10px;
		 	margin-top: 11px;
		 	
		 	.plcc-banner {
			    background: url('../../../images/ww/banner-background-mobile.png') 0 0 no-repeat;
			 	background-size: 100% 100%;
			 	height: 200px;
		 	}
		 	
		 	.plcc-banner-main {
		 		max-width: 265px;
		 	}
		 	
		 	.plcc-pre-approved-name {
			    font-size: $base-font + 6px;
			    margin: 11px 0 0 0;
		 	}
		 	
		 	.credit-card {
		 		font-size: $base-font - 2px;
		 	}
		 	
		 	.bonus-reward {
			    margin: 12px 0 7px;
			        
			    h1 {
			    	font-size: $base-font + 10.5px;
			    	letter-spacing: 7px;
			    }
			    
			    h2 {
			    	font-size: $base-font - 2px;
			    	margin: 10px 0 0 0;
			    }
			    
			    ul {
		    		li {
		    			width: 50%;
		    			float: left;
		    			margin: 23px 0 35px;
		    			
		    			&:nth-child(2n) {
		    				float: right;
		    				clear: none;
		    			}
		    			
		    			&:nth-child(odd) {
		    				clear: both;
		    			}
		    			
		    			&.earn-rewards {
		    				width: 50%;
		    				
		    				.section {
		    					max-width: 120px;
		    				}
		    			}
		    			
		    			&.offer {
		    				width: 50%;
		    				
		    				.section {
		    					max-width: 120px;
		    				}
		    			}
		    			
		    			&.free-shipping {
		    				width: 50%;
		    				
		    				.section {
		    					max-width: 86px;
		    				}
		    			}
		    			
		    			.section {
		    				max-width: 120px;
		    				margin: 0 auto;
		    			}
		    			
		    			span {
		    				font-size: $base-font;
		    				line-height: 20px;
		    			}
		    			
		    			.free-shipping {
		    				img {
		    					padding: 15px 0 0 0;
		    				}
		    			}
		    		}
			    }
		 	}
		}
		
		.pre-screen-notice {
			padding: 0 10px;
		 	font-size: $base-font;
		 	
		 	span {
		 		border-bottom: 2px solid $black;
		 		margin: 0 0 2px 0;
		 	}
		 	
		 	.prescreen-message {
			    padding: 18px 3px;
		 	}
		}
		
		.legal-copy {
			padding: 0 10px;
		 	margin: 20px 0 0 0;
		 	font-size: $base-font;
		 	
		 	span {
		 		width: 100%;
		 		margin: 16px 0;
		 		display: inline-block;
		 	}
		 	
		 	a {
		 		border-bottom: 1px solid $black;
		    	color: $black;
		 	}
		}
		
		.form-row-button {
			padding: 10px 0;
		    
		    button {
		    	margin: 0 0 0 10px;
		    	font-size: $base-font;
		    	
		    	&.plcc-nothanks-button {
		    		padding: 15px 18px;
		    	}
		    	
		    	&.plcc-get-it-button {
		    		padding: 17px 28px;
    				margin: 0 10px 0 0
		    	}
		    }
		}
	}
	.plcc-apply-model {
		width: 76%;
		.header {
    		font-size: $base-font + 3px;
    		margin: 8% 0 3% 0;
		}
		
		.sub-header {
			font-size: $base-font - 1px;
		}
		
		button {
			font-size: $base-font;
		}
	}
	
	.plcc-apply-error {
		width: 86%;
	}
	
	.approved-model {
		.header {
			font-size: $base-font + 3px;
		    margin: 0 auto;
		    margin-bottom: 15px;	
		}
		
		.sub-header {
			font-size: $base-font - 3px;
    		margin-bottom: 18px;
    		width: 72%;
		}
		
		.continue-message {
			font-size: $base-font - 3px;
		}
		
		.approved-buttons {
			.guest-sign-in {
				margin: 0 0 20px 0;
    			padding: 17px 24px;
    			width: 100%; 
    			font-size: $base-font;
			}
			.continue-shopping {
				padding: 17px 35px;
				width: 100%; 
    			font-size: $base-font;
			}
		}
	}
	.plcc-acquisition-rebuttal-model {
		.offer-content {
			padding: 0 10px;
		}
		
		.rebuttal-banner-text {
			width: 93%;
			top: 47%;
    		
    		.welcome {
    			font-size: $base-font;
    			margin: 0 0 13px 0;
    			line-height: 18px;
    			color: $white;
    		}
    		
    		.pre-approved {
    			width: 86%;
			    margin: 0 0 14px 0;
			    line-height: 18px;
			    font-size: $base-font;
			    color: $white;
    		}
    		
    		.extra {
    			font-size: $base-font + 8px;
			    line-height: normal;
			    width: 100%;
			    margin: 0 0 18px 0;
    		}
    		
    		.accept {
    			font-size: $base-font;
			    line-height: normal;
			    color: $white;
    		}
    		
    		.reward-plus {
    			font-size: $base-font + 8px;
			    line-height: normal;
			    font-family: $HelveticaBold;
			    color: $white;
			    text-transform: uppercase;
			    letter-spacing: 8px;
    		}
    		
    		.bonus-reward {
    			font-size: $base-font + 8px;
			    line-height: 18px;
			    color: $white;
			    font-family: $HelveticaBold;
			    text-transform: uppercase;
			    letter-spacing: 8px;
			    margin: 0 0 12px 0;
    		}
    		
    		.billing  {
    			font-size: $base-font;
    		}
		}
		
		.rebuttal-section {
			padding: 0 10px;
		}
		
		.pre-screen-notice {
			font-size: $base-font;
    		padding: 10px 0.7px;
		}
		
		.legal-copy {
			padding: 0;
    		font-size: $base-font;
    		
    		span {
    			margin: 20px 0 15px 0;
    		}
		}
		
		.form-row-button {
			margin: 10px;
			
			.plcc-nothanks-button {
				 padding: 15px 19px;
				 font-size: $base-font;
			}
			
			.plcc-get-it-button {
				padding: 15px 19px;
				font-size: $base-font;
			}
		}
	}
}
}