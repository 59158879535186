@media screen and (min-width: 1024px) {
	.menu-category .hoverIntent .has-sub-menu::after {
		border:2px solid $blue-haze;
		border-radius: 0;
	}
}


.menu-category 
	.hoverIntent
		.level-2 {
			@media screen and (min-width: 1024px) {
				border-bottom: 4px solid #d8d7df;
				background: $wild-sand;
				.sub-cat-wrapper {
					background: none;
					border: none;
				}
				a {
					&:hover {
						color: $blue-haze;
					}
				}
				> ul {
					> li {
						.boldCat {
							a {
								@include WorkSansMedium($WorkSans);
							}
						}
					}
				}
			}
		}



@media screen and (max-width: 1023px) {
	.menu-category a {
		color: $black;
	}
	.menu-footer {
        .footer-links,
        .cs-flyout-quick-links {
            font-family: $WorkSans;
            a {
                font-family: $WorkSans;
            }
        }
    }
	.slider-menu {		
	    #navigation {
			.menu-utility {
				.shopall-brands {
				    @include WorkSansMedium($WorkSans);
				}
				.navigation-scroll-inner {
					background: $FB-lighter-gray;
				}
				.menu-footer {
					.footer-nav {
						.footer-email-heading {
							@include WorkSansMedium($WorkSans);
						}
						p {
							@include WorkSansMedium($WorkSans);
						}
						.footer-email-signup {
							.email-signup {
								input[type="text"] {
									font-family: $WorkSans;
								}
				
								button {
									background-color: $black;
									font-family: $HurmeBlack;
								}
							}
						}
						.footer_list_wrapper {
							.footer_item {
								&:nth-child(1) {
									.footer-heading {
										@include WorkSansBold($WorkSans);
									}
									ul {
										li {
											a {
												font-family: $WorkSans;
											}
										}
									}
								}
							}
						}
					}
				}			
			}
	    }
    }
}

@media screen and (max-width:767px) {
	.slider-menu {
		#navigation {
			.mobile-sign-in {
				.right-section {
					.info {
					    font-family: "Helvetica Neue LT W01_65 Md";
					}
					.user-info {
    					.login-user {
	    					@include WorkSansMedium($WorkSans);
	    					.my-account {
	    						@include WorkSansMedium($WorkSans);
	    					}
				    	}
				    	
				    	span {
				    		&.username {
							    @include WorkSansMedium($WorkSans);
				    		}
				    	}
					}
				}
			}
			.menu-footer {
				.primary-links {					
					h2 {
						@include WorkSansMedium($WorkSans);
					}
					
					span {
						font-family: $WorkSans !important;
					}

					.bcText {
						font-family: "Work Sans", sans-serif;
					}
					
				}
				
				.cs-flyout-quick-links{
					.links{
					    @include WorkSansMedium($WorkSans);
					}
					a{
						@include WorkSansMedium($WorkSans);
					}
				}
			}    
		}
	}
}

@media screen and (min-width: 1024px) {
	.nonBoldCat {
		font-family: $WorkSans;
		
		a {
			font-family: $WorkSans !important;
		}
	}
	.boldCat {
		@include WorkSansMedium($WorkSans);
		
		a {
		   @include WorkSansMedium($WorkSans);
		}
	}
}

/* Navigation for responsive */
@media screen and (min-width: 1024px) {	
	.product-list-page {
		#navigation {
			.menu-category {
				li {
					&.selected {
						>a {
							color: $blue-haze;
							border-bottom: 4px solid $blue-haze;
						}
						.level-2 {
							>a {
								color: $black;
								border-bottom: 4px solid transparent;
							}
						}
					} 
				}
			}
		}
	}
}

#wrapper.menu-active {
    @media screen and (max-width: 1023px) {		
		.top-banner .header-mian-banner {
			.menuUnderLay{
				&:after{
					background: $FB-lighter-gray;	
				}
			}
		}
    }
	
}
//act-menu css
@media screen and (max-width: 767px) {
	#wrapper.menu-active.account-active {
		#header .top-menu-utility {
			.menu-utility .menu-utility-user {
				.right-section .user-info {
					.user-panel.registered-user {
						.my-account.heading {
							@include WorkSansMedium($WorkSans);
						}
						
					}
				}
			}
		}
		
	}
}
@media screen and (max-width: 1023px) {
    .header-mobile-menu-open {
        .navigation-header-wrapper {
            .mobile-menu-logo {
                img {
                     vertical-align: text-bottom;
                     margin-top:3px;
                }
            }
        }
    }
}

//SC-3513
@media screen and (min-width: 1024px) {
	.menu-category {
		/*&.new-menu-l1{
			a {
				&:hover{
					color: $blue-haze;
				}
			}
			.hoverIntent { 
				.has-sub-menu{
					&:after{
						
						background: $blue-haze;
						border-top:3px solid $blue-haze;
					}
				} 
			}
		}*/
		.hoverIntent {
			.level-2 {
				&.new-mega-menu-L2{
            		background:$white;
            		border-bottom:4px solid $blue-haze;
            	} 
				
				.sub-cat-wrapper {				
					&.new-mega-menu{
						//border-top: 1px solid $blue-haze;
							border-bottom:0;
						.nonBoldCat,.boldCat { 
							a {
								color: $black;
								&:hover {
									color: $blue-haze;
								}
							}
						}
						.nonBoldCat{
							a {
								font-weight: 500;
							}
						}
					}
				}
			}
		}
	}
}