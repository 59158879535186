.pt_product-search-noresult {
	.primary-content {
	    padding: 0 0 30px;
	   
	    .section-header {
	    	background-color: $white-smokedip;
			padding: 11px 11px 5px 25px; 
			font-size: $base-font + 1px;
			 @include WorkSansMedium($WorkSans);
			@include prefix(border-radius,5px, $prefixArray);
		
			p {
			    padding: 2px 2px 6px 2px;
				color: $dim-gray;
			}
		
			.no-hits-search {
				input[type="text"] {
					border: none;
				    background: transparent;
				    text-transform: capitalize;
				    width: 90%;
				    padding: 0;
				    font-size: $base-font + 34px;
				    height: auto;
				    box-sizing: border-box;
				    -webkit-box-sizing: border-box;
 					-moz-box-sizing: border-box;
				    @include WorkSansMedium($WorkSans);
				    outline: none;
				    box-shadow: none;
				    -webkit-box-shadow: none;
				    -moz-box-shadow: none;
				}
		
				button {
				    margin: 0 20px 0 0;
				    width: 34px;
    				height: 36px;
				}
			}
	    }
	}
}

.popular-searches {
    padding: 55px 0;
     @include WorkSansMedium($WorkSans);
   
    .no-results-heading {
    	font-size: $base-font + 18px;
    	@include WorkSansMedium($WorkSans);
    	text-transform: capitalize;
    }
   
    .search-term {
    	font-size: $base-font + 8px;
    	margin: 15px -5px 0 0;
    	color: $dim-gray;
    }
   
    .search-brands {
    	padding: 21px 0 0 0;
   
    	a {
    		font-size: $base-font + 2px;
    		@include WorkSansBold($WorkSans);
    	}
    }
   
    .did-you-mean {
    	font-size: $base-font + 6px;
    	margin: 16px 0 0 0;
   
    	.no-hits-search-term-suggest {
    		@include WorkSansBold($WorkSans);
    		color: $black;
    		letter-spacing: 4px;
    	}
    }
   
    .shop-all {
    	margin: 41px 0 0 0;
   
    	.button {
    		font-size: $base-font + 2px;
    		@include WorkSansSemiBold($WorkSans);
    		padding: 14px 35.5px;
   			background:transparent;
   			
   			&.shop-newarrivals {
   				margin-right: 9px;
			    letter-spacing: 01px;
			    padding: 15px 32.5px;
   			}
   			
    		&.shop-clearance{
    			margin-left: 15px;
			    letter-spacing: 0.7px;
			    padding: 15px 46.5px;
    		}
    	}
    }
}
@media screen and (max-width:1023px) {
	.pt_product-search-result  {
		#main {
			width: 100%;
		}
	}
}

@media screen and (min-width:768px) and (max-width: 1023px){
	.pt_product-search-noresult { 
		.primary-content {
			.section-header {
				padding: 16px 11px 5px 34px;
				p {
					font-size: 14px;
				}
				.no-hits-search {
					input[type=text] {
						font-size: 50px;
					}
					button {
						margin: -5px 26px 0 0;
					}
				}
			}
		}
	}
	.popular-searches {
		padding: 54px 0;
		.did-you-mean {
			padding: 24px;
			margin: 11px 0 0 0;
			.no-hits-search-term-suggest {
				 letter-spacing: 7px;
			} 
		}
		.search-term {
			font-size: 20px;
    		margin: 17px -5px 0 0;
		}
		.search-brands {
			padding: 18px 0 0 0;
			a {
				font-size: 14px;
			}
		}
		.shop-all {
			margin: 42px 0 0 0;
			.button{
				&.shop-newarrivals {
					margin-right: 18px;
				    letter-spacing: 1px;
				    padding: 14px 31px;
				    display: inline-block;
				}
				&.shop-clearance {
					margin-left: 11px;
				    display: inline-block;
				    letter-spacing: 1px;
				    padding: 14px 42px;
				    margin-right: 0;
				}
				 letter-spacing: 1.5px;
				 padding: 14px 33px;
			}
		}
	} 
}

@media screen and (max-width:767px){
	.pt_product-search-noresult {
		.primary-content {
			.section-header {
				 padding: 17px 11px 17px 25px;
				 .no-hits-search {
				 	input[type="text"] {
				 		 width: 80%;
				 		 font-size: 24px;
				 	}
				 	button {
				 		width: 20px;
    					height: 25px;
    					margin-top:0;
				 	}
				 }
			}
		}
	}
	.popular-searches {
		padding: 20px 0;
		.search-term {		
    		margin: 8px 0 0 0;
    		font-size: $base-font + 2px;
		} 
		.search-brands {
			padding: 10px 0 0 0;
			a {
				font-size: $base-font;
			}
		}
		.shop-all {
			margin: 25px 0 0 0;
		}
	}  
}

@media screen and (max-width: 639px) {
	.popular-searches {
		.shop-all {
			.button {
				font-size: 12px;
				padding: 10px 5px;
				&.shop-newarrivals {
					margin-right: 0;
					margin-bottom: 1em;				
				}
				&.shop-clearance {
					margin-left: 0;				
				}
			}
		}
	}
}

//Trending Now
.trending-section {	
	.you-may-like {
		padding-top: 20px;
	}
	
	.recommendation-heading {
		margin: 10px 0 30px;
		
		span {			
			&.trending {
				 @include WorkSansMedium($WorkSans);
				font-size: $base-font + 18px;
				letter-spacing: 1px;
				
				@media screen and (max-width: 767px) {
					font-size: $base-font + 12px;
				}
			}
		}
	}
}
