.custom-select {
    .selected-option {
        @include WorkSansRegular($WorkSans);
        font-weight: normal;
		border-radius: 0;
		padding: 0 21px;
		font-size: $base-font + 4px;
    }
    .selection-list {
		
        li {
            @include WorkSansRegular($WorkSans);
            font-weight: normal;
			padding: 9px 0 9px 21px;
			font-size: $base-font + 4px;
        }
    }
    &.current_item {
        .selected-option {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        .selection-list {
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }
    select {
        @media screen and (max-width: 1024px) {
            padding:0 20px;
        }
    }
}

.mm-prod-details {
    .custom-select {
        .selected-option {
            line-height: 38px;
            min-height: 38px;
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            @include WorkSansBold($WorkSans);
        }
    }
}

.pt_checkout {
    .form-row {
        .field-wrapper {
            .selection-list {
                li {
                    font-family: $Helvetica;
                    font-size: $base-font + 1px;
                }
            }
        }
    }
}

.pt_checkout {
    .custom-select {
        .selected-option {
            font-family: $Helvetica;
            font-weight: normal;
            font-size: $base-font + 1px;
            text-transform: none;
            border-radius: 0;
        }
        .selection-list {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            li {
                border-bottom: 0;
                font-family: $Helvetica;
            }
        }
    }
}

.pt_account {
    .custom-select {
        .selected-option {
            @include WorkSansMedium($WorkSans);
            font-weight: normal;
            text-transform: none;
            color: $dusty-gray;
        }
        .selection-list {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            li {
                border-bottom: 0;
                font-family: $Helvetica;
                width: 100%;
                padding-right :0px;
            }
        }
    }
}