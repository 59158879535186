/* FB-site breadcrumbs css start */
.breadcrumb { 
	font-family: $WorkSans;
	font-size: $base-font;
	letter-spacing: 0.2px;

	ol li{
		+ li::before {
			content: '>';
			color: $FB-dark-gray;			
		}
	}

	.breadcrumb-element {
		color: $FB-dark-gray;
		text-transform: capitalize;
		font-weight: normal;
		&.current-element {
			font-family: $WorkSans;
			letter-spacing: 0.2px;
			font-weight: 500;
		}
	}

	.breadcrumb-slash {
		&:after {
			color: $FB-dark-gray;
			content: '>';
			padding: 0 1px 0 2px;
			pointer-events: none;
			cursor: default;
		}		
	}

    @media screen and (min-width: 1024px) {    	
	    border-radius: 6px;
	    background-color: transparent;
	    padding: 5px 7px;
    }

	@media screen and (max-width: 1023px) {
		/* breadcrumb tablet media query start */

	} /* breadcrumb tablet media query end */

	@media screen and (max-width: 767px) {	
		/* breadcrumb mobile media query start */
		.breadcrumb-element {
			&.current-element {
				margin: 6px 4px;
			}
		}
	} /* breadcrumb mobile media query end */
}

/* FB-site breadcrumbs css end */