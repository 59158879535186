.ui-dialog {
	&.cart-overlay {
		-webkit-box-shadow: 1px 6px 25px 8px rgba(200,200,200,1);
		-moz-box-shadow: 1px 6px 25px 8px rgba(200,200,200,1);
		box-shadow: 1px 6px 25px 8px rgba(200,200,200,1);
		padding: 10px;
    	box-sizing: border-box; 
		
		.button.secondary {
			border: solid 3px $black;
			color: $black;
		}
		+.ui-widget-overlay {
			background: #FFFFFF;
		}
		.pt_cart {
			#main {
				padding: 0 0;
			}
		}
		
		.primary-content {
			padding: 0 0;
		}
		.ui-widget-header {
			 border-bottom: 1px solid $white-smoke;
			 width: 100%;
			 text-align: center;
			 margin-bottom: 23px;
			 
			 @media screen and (max-width: 480px) {
          		text-align: left;
             }
            
			 .ui-dialog-title {
				display: inline-block;
				font-size: $base-font + 8px;
				@include WorkSansMedium($WorkSans);
				color: $black;
			    text-align: center;
				text-transform: none;
			    margin: 9px 0 0 -23px;
				background: url("../../../images/Checkmark_Icon_Green.svg") left center no-repeat;
				background-size: 25px;
				padding-left: 30px;
    			float: none;
    			width: auto;
    			margin-left: 13px;
    			letter-spacing: 0.5px;
			}
		} 
		
		.ui-dialog-content {
			height: auto !important; 
			
			@media screen and (max-width: 959px) and (min-width: 768px) {
				overflow: auto;
			}
			.mini-cart-content.add-to-bag-product-brylane{
				.mini-cart-product .left-content{
					.mini-cart-image img{
						object-fit: cover;
    					min-height: 301px;
    					@media screen and (max-width: 360px) {
    						min-height: 185px;
    					}
	    				@media screen and (min-width: 361px) and (max-width: 767px) {
	    					min-height: 192px; 
	    				}
					}
				}
			}
		}
		
		.special-product-set-overlay {
			.right-content {
				.mini-cart-name {
					margin-bottom: 0;
					border-bottom: 0;
					padding-bottom: 5px;
				}
				div {
					font-size: $base-font;
					
					span.label {
						margin-bottom: 9px; 
						font-size: $base-font;  
					}
				}
			}
			div.price-promotion {
				margin-bottom: 7px;
			}
			
			.special-productset-child-wrapper {
				border-top: 1px solid $alto;
				border-bottom: 1px solid $alto;
				display: inline-block;
    			width: 100%;
				
				.special-productset-child {
				    display: inline-block;
				    width: 100%;
				    margin-top: 15px;
				    
				    div {
						font-size: $base-font - 1px; 
						
						span.label {
							font-size: $base-font - 1px; 
						}
					}
				}
				
				.mini-cart-image {
					width: 60px;
    				float: left;
				}
				.mini-cart-product-info {
					width: 214px;
					float: left;
					padding-left: 15px;
					
					.mini-cart-name {
						@include WorkSansSemiBold($WorkSans);
						font-size: $base-font + 4px;
					}
				}
			}
		}
	}
}

.cart-overlay {
	.mini-cart-content {
		.mini-cart-totals {
			box-sizing: border-box;
			font-size: 14px;
   			text-transform: uppercase;
			display: table;
			text-align: left;
			width: 100%; 
   			
   			.value {
   				font-family: $WorkSans;
   				font-weight: 700;
   			}
   			.hide {
   				display: block;
				border-top: 1px solid $white-smoke;
				padding: 20px 0 0 ;
   			}
   			 
   			&.only-for-mobile { 
   				display: none;
   				@media screen and (max-width:767px){ 
   					display: block;
				    width: 77%;
				    margin: auto;
				    text-align: left;
   				}
   			}
   			&.hide-mobile {
   				@media screen and (max-width:767px){ 
   					display: none;
   				}
   			}
		}
	
		.mini-cart-product {
			width: 100%;
    		display: table;
    		@media screen and (max-width:767px){
    			display: inline-block; 
    		}
    		
    		div.price-promotion {
    			display: inline-block;
    			margin-bottom: 15px;
    		}
    		
    		.slick-slide {
    			img {
    				margin: auto;
    			}
    		}
			.left-content {
				display:table-cell;
				width:50%;
    			vertical-align: middle;
    			.mini-cart-image {
    				max-width: 209px;
    				margin:0 auto;
					text-align: center;
    				position: relative;
    				img {
    					margin :0 auto;
    				}
    				.brand-logo {
					    margin-top: 10px;
					    width: 100%;
					    text-align: center;
					    display: none;
    				}
    			}
			} 
			
			.right-content {
				display:table-cell;
				width:50%;
    			max-width: 361px;
    			padding:0 40px 0 20px;
				vertical-align: middle;
    			
    			div {
    				font-size: $base-font;
    				font-family: $WorkSans;
    				text-transform: capitalize;
    				
    				span {
    					&.label {
    						font-family: $WorkSans;
    						font-weight: normal;
    						text-transform: capitalize;
							margin-bottom: 15px;
						    display: inline-block;
					        letter-spacing: 0.3px;
					        font-size: $base-font + 2px;
					        &.baseprice {
					        	margin-bottom: 0px;
					        }
    					}
    					&.value, &.is-in-stock{
    						font-weight: bold;
    						letter-spacing: 0.3px;
    						font-size: $base-font + 2px;
    					}
    					&.mini-cart-price {
    						color: $dark-red;
    					}
					}
					
					&.attribute {
						&[data-attribute="braCupSize"] {
							span.value {
								text-transform: uppercase;
							}
						}
					}

    				&.product-price{
    					font-weight: bold;
    					letter-spacing: 0.3px;
    					font-size: $base-font + 2px;
    				}
    			}
    			.mini-cart-subtotals{
					font-size: $base-font + 2px;
					letter-spacing: 0.4px;
    				.label{
    					@include WorkSansRegular($WorkSans);
    				}
    				.value{
    					@include WorkSansBold($WorkSans);
    				}
    			}
    			.mini-cart-name {
    				font-size: $base-font + 6px;
					font-family: $HurmeSemiBold;
					text-transform: uppercase;
    				margin-bottom: 18px;
				    padding-bottom: 12px;
				    border-bottom: 1px solid $white-smoke;
				    letter-spacing: 1px;
				    font-weight: 600;
    			}
    			
    			.product-availability-list {
    				margin: 0;
    				padding: 0;
    				
    				li {
    					padding: 0 0 10px 0;
    				}
    			}
    			.mini-cart-pricing {
    				.price,.product-price {
    					float: left;
    					margin-right: 4px;
    				}
    				.product-price {
    					span {
                            color: $black;
                            &.price-standard-exist {
                                color: $medium-carmine;
                            }
                            &.price-standard {
                                color: $black;
                                text-decoration: line-through;
                            }
                        }
    				}
    				.total-price {
    					clear: both;
    				}
    			}
			}
		}
		.cart-recommendations {
			.you-may-like {
				.trending {
					font-size: $base-font + 6px;
				}
				.product-tile {
					.product-name {
						font-size: $base-font;
					}
				}
			}
		}
	}
	
	//recommedation
	.you-may-like {
		background: $FB-mercury-light;
	    padding: 10px 0 7px;
	    font-style: normal;
   		text-align: center;
	    display: inline-block;
	    width: 100%;
	    font-weight: bold;
	    
		h2 {
			color: $black;
			font-family: $HurmeBlack;
			font-size: $base-font + 4px;
			font-style: normal;
    		padding: 2px 0 4px;
    		margin: 0;
    		text-transform: uppercase;
    		letter-spacing: 1px;
		}
		
		.product-tile {
		    padding: 10px 20px 0;
	        letter-spacing: 0;
		    width: 24.6%;
		    float: left;
		    margin: 0 0;
		    box-sizing: border-box;
		    
		    .product-image {
		    	max-width: 80px;
		    	margin: auto;
		    	
		    	img {
	    		    height: auto;
	    		    max-width:100%;
		    	}
		    }
		    .product-name {
		    	font-size: $base-font + 3px;
	    	    margin-top: 2px;
		    }
		}
	}
}

@media screen and (max-width:767px){
	.ui-dialog {
		&.cart-overlay {
			box-shadow: none;
			max-width: 93%; 
		    left: 3.5% !important;
		    overflow: auto;
		    
		    .ui-dialog-titlebar-close {
		    	span.ui-button-text {
		    		background: url("../../../images/cart-close.png") no-repeat;
		    		width: 100%;
    				height: 100%;
		    	}
		    }
		    +.ui-widget-overlay {
				background: #333333;
			}
			.pt_cart #main {
				margin: 0 0;
			}
			
		    .mini-cart-content {
		    	display: block;
		    	max-width: 100%;
			    position: static;
			    box-shadow: none;
			    margin: 0 0;
		    }
		    
		    .ui-dialog-buttonpane {
		    	.ui-dialog-buttonset {
		    		width: 77%;
	    		    margin: 10px auto;
	    		    
		    		button {
		    			width: 100%;
		    		}
		    		button:nth-of-type(1) {
						background: $black;
						border-color: $black;
						color: $white;
					}
		    		button:nth-of-type(2) { 
		    			margin: 20px 0;
		    			background: transparent;
		    			border-color: $dim-gray;
						color: $dim-gray;
		    		}
		    	}
		    }
		    
		    .mini-cart-content {
			    .special-product-set-overlay {
			    	.left-content {
			    		display: none;
			    	}
			    	
					.right-content {
						width: 100%;
						padding: 0 10px;
						max-width: 100%;
					}
					
					.special-productset-child-wrapper {
						.mini-cart-image {
							display: none;
						}
						.mini-cart-product-info {
							padding-left: 0;
						}
					}
				}
			}
			
		    .slick-dots {
		    	width: 100%;
   				text-align: center;
   				padding: 0;
			    list-style: none;
			    
   				li {
   					display: inline-block;
				    width: 17px;
				    height: 17px;
				    margin: 0 7px;
				    padding: 0;
				    cursor: pointer;
				    background: $light-gray;
    				border-radius: 50%;
				    
				    &.slick-active {
				    	background: $black;
				    }
				    	
				    button {
				    	font-size: 0;
					    line-height: 0;
					    display: block;
					    width: 100%;
					    height: 100%;
					    padding: 5px;
					    cursor: pointer;
					    color: transparent;
					    border: 0;
					    outline: none;
					    background: transparent;
				    }
   				}
		    }
		}
	}
	
	.cart-overlay {
		.mini-cart-content {
			.mini-cart-products  {
				padding: 0 0;
				border-bottom: 1px solid $white-smoke;
			}
			
			.mini-cart-product {
				border: 0;
			    padding: 0 0;
			    
				.left-content {
					max-width: 270px;
   					 margin: auto;
				}
				
				.right-content {
		    		padding-left: 20px;
		    		width: 50%;
		    		div {
	    				span {
	    					&.label {
	    						margin-bottom: 10px;
	    						padding: 0;
	    					}
	    				}
	    			}
		    		.mini-cart-name,.mini-cart-pricing,.mini-cart-attributes {
		    			width:100%;
		    		}
		    		.mini-cart-attributes {
		    			span {
		    				&.value {
								font-family:$Helvetica;		    				
		    				}
		    			}
		    		}
		    		.mini-cart-name {
		    			font-size: $base-font + 4px;
	    			    margin-bottom: 9px;
    					border-bottom: 0;
    					padding-bottom: 0;
		    		}
		    	}
			}
			
			.mini-cart-totals {
				float: none;
    			width: 100%;
			    margin-left: 0;
    			text-align: center;
    			padding-top: 0;
    			
    			.mini-cart-subtotals {
    				padding: 15px 0;
    				font-size: 12px;
				    border-top: 0;
				
    				.value {
    					font-family: $Helvetica;
    				}
    			}
			}
		}
	}
}

@media screen and (max-width:480px){
	.ui-dialog {
		&.cart-overlay {
			padding: 5px;
			
			.ui-dialog-buttonpane {
			 	.ui-dialog-buttonset {
			 		button {
			 			padding: 10px 5px;
			 			font-size: 12px;
			 		}
			 	}
			}
			
			.mini-cart-content {
				.special-product-set-overlay {
					.right-content {
						width: 100%;
					}
				}
			}
			
			.slick-dots {
   				li {
				    width: 13px;
				    height: 13px;
				    margin: 0 5px;
				}
			}
		}
	}
	.cart-overlay {
		.mini-cart-content {
			.mini-cart-product {
				.left-content {
					width: 40%;
    
				}
				.right-content {
					width: 60%;
					padding:0 0 0 10px;
					div {
						/*font-size: 10px;*/
						
						span {
							&.label {
								/*font-size: 10px;*/
							}
						}
					}
				}
			}
		}
	}
}
