.plcc-landing-content {
	.plcc-top-banner {
		.jl,.rm {
			display: none;
		} 
	}
	
	.plcc-tabs {
		font-size: $base-font + 8px;
		@include WorkSansBold($WorkSans);
		
		.tab {
			letter-spacing: 5px;
			padding-bottom: 1px;
			
			&.active {
				color: $black;
				border-bottom: 3px solid $black;
			} 
		}
	}
	.benefits {
		.tiles-wrapper {
			.tile {
				h1 {
					@include WorkSansMedium($WorkSans);
					font-size: $base-font + 12px;
				}
			}
		}
		
		.brands-list {
		    margin: 55px 0 28px;
			h1 {
				font-size: $base-font + 12px;
				font-family: $WorkSans;
			}
		}
	}
	
	.rewards {
		margin: 158px 0 52px;
		
		.circle {
		    background: $light-pink;
			background: -moz-linear-gradient(-45deg,  $light-pink 0%, $light-pink 39%, $light-blue 73%, $light-blue 100%);
			background: -webkit-linear-gradient(-45deg,  $light-pink 0%,$light-pink 39%,$light-blue 73%,$light-blue 100%);
			background: linear-gradient(135deg,  $light-pink 0%,$light-pink 39%,$light-blue 73%,$light-blue 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$light-pink', endColorstr='$light-blue',GradientType=1 );
		    
		    .text-content {
	    	    max-width: 300px;
	    	    
			    h1 {
			    	color: $black;
			    	font-size: $base-font + 14px;
			    	
	    			&.bold {
	    				@include WorkSansBold($WorkSans);
    					font-size: $base-font + 12px;
	    			}
	    		}
	    	}
		}
		.table-wrapper {
			.table-content {
			}
		}
		table th {
			@include WorkSansMedium($WorkSans);
		}
		table td {
			color: $dim-gray;
			@include WorkSansRegular($WorkSans);
		}
		
		.reward-look {
			color: $dim-gray;
			u {
				color: $black;
			}
		}
	}
	.perks-email-wrapper {
	    margin: 45px auto 47px;
	    
		.perks-signup {
		    .plcc-landing-perks { 
		    	h1 {
		    		@include WorkSansBold($WorkSans);
					font-size: $base-font + 21.6px;
					letter-spacing: 10px;
				    line-height: 42px;
				    margin-top: 0;
		    	}
		    }
		    .plccbenefits-n-email {
			    .plcc-platinumcard-benefits {
			    	.plcc-benefits-head {
			    		@include WorkSansMedium($WorkSans);
			    	}
			    	.plcc-benefit-msg {
			    	}
			    }
			}
		}
		
		.perks-offer {
		    margin-top: 0;
	    	
	    	.per-off-content {
	    		padding: 16px;
    		    margin-top: 10px;
    		    
    		    span {
					font-size: $base-font + 6px;
		    	}
		    	h1 {
		    		@include WorkSansBold($WorkSans);
		    		font-size: $base-font + 28px;
		    		color: $black;
	    		    line-height: 40px;
		    	}
	    	}
	    }
	}
	
	.plcc-bottom {
		.bottom-right {
			h1 {
				@include WorkSansBold($WorkSans);
	    		font-size: $base-font + 12px;
	    		line-height: 33px;
	    		margin-bottom: 18px;
			}
		}
		.bottom-left {
			.qa-section {
				.qa-content {
			        padding: 22px;
				}
			}
		}
		
		.plcc-text-content {
			margin: 45px 0 0;
		}
	}
	.pre-approved-cta {
		h1 {
			@include WorkSansBold($WorkSans);
			font-size: $base-font + 12px; 
		}
		.approved {
			@include WorkSansSemiBold($WorkSans);
			color: $mineshaft;
		}
		.sign-in {
			@include WorkSansSemiBold($WorkSans);
			text-decoration: underline;
			color: $black;
		    margin-top: 12px;
		}
		button {
	        margin: 13px 0 32px;
		}	
	}
}

@media screen and (max-width: 1240px) {
	.plcc-landing-content {
		.plcc-tabs {
			&.four-links {
				.tab {
					margin: 0 4.2%;
				}
			}
		}
	}
}


@media screen and (max-width: 1023px) {
	.plcc-landing-content {
		.plcc-tabs {
			&.four-links {
				.tab {
					margin: 0 3.2%;
				}
			}
		}
		.plcc-top-banner {
			overflow: hidden;
		}
	
		.benefits {
			.tiles-wrapper {
				.row.row-2 {
					margin-top: 48px;
				}
			}
			.brands-list {
			    margin: 55px 0 35px;
			}
		} 
		.rewards {
			margin: 40px 0 52px;
			.circle {
				.inner-circle {
					margin: 3.6% auto 0 3.6%;
					display: inline-block; 
					
				}
			}
			.reward-look {
				margin: 30px 0 10px; 
			}
		}
		.perks-email-wrapper {
			margin: 27px auto 35px;
			
			.perks-offer {
			    padding-bottom: 0;
    			border-bottom: 0;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_specials {
		#main {
			padding: 0 10px; 
		}
	}
	
	.plcc-landing-content {
		.plcc-tabs {
			&.four-links {
				.tab {
					margin: 0 1.2%;
				}
			}
		}
	
		h1 {
			font-size: $base-font;
			line-height: normal; 
		}
		
		.plcc-tabs {
			display: inline-block;
			font-size: $base-font - 2px;
    		padding: 10px 0;
				
			.tab {
			    letter-spacing: 2px; 
			    width: auto;
    			display: inherit;
			    
			    &.active {
			    	border-width: 2px;
			    }
			}
		}
		
		.benefits {
			.tiles-wrapper {
				.tile {
					width: 50%;
					margin: 0 0;
				    padding: 6px;
				    min-height: 247px;
				    
				    h1 {
   						margin: 13px 0 11px 0;	
   						font-size: $base-font;
						line-height: normal; 
				    }
				    span  {
				    	font-size: $base-font - 1px;
				    	line-height: normal; 
				    }
				}
				.tile:nth-child(even) {
					float:right;
				}
				.row {
					.row-2 {
						margin-top: 0;
					}
				}
			}
			.pre-approved-cta {
			 	button {
			 		max-width: 62%;
		 		    margin: 20px 0 16px;
			 	}
			}
			.brands-list {
				display: none;
			}
		}
		
		.rewards {
			margin: 55px 0 27px;
			padding: 0 0;
			
			.circle {
				width: 84vw;
    			height: 84vw;
    			width: 247.5px;
    			height: 247.5px;
    			
				.text-content {
				    max-width: 180px;
				    margin: 20% auto 0;
				    
					h1 {
					    font-size: $base-font;
					    margin-bottom: 3%;
					    line-height: 16px;
					    
					    &.bold {
					    	font-size: $base-font;
					    }
					}
				}
			}
			.table-wrapper {
			    margin: -32.5% auto 0;
			    margin: -62px auto 0;
    			max-width: 301px;
			    
				.table-content {
					padding: 5px;
				}
			}
			table th {
				font-size: $base-font - 2px; 
				padding: 5px 10px;
			}
			table td {
				font-size: $base-font;
				padding: 5px 10px;
			}
			
			.reward-look {
				width: 100%;
    			font-size: $base-font - 1px;
	    		line-height: 16px;
			}
		}
		
		.perks-email-wrapper {
			margin: 5px auto 18px;
		    padding: 0 0;
			
			.perks-signup {
				.plcc-landing-perks {
					float: none;
					
					h1 {
						font-size: $base-font + 16.5px;
						margin-top: 2px;
					}
				}
				
				.plccbenefits-n-email {
					text-align: center;
					float: none;
				    margin: auto;
				    width: 72.6%;
				
					.plcc-platinumcard-benefits {
						.plcc-benefits-head {
							font-size: $base-font;
						    margin: 4px 0 7px;
						}
						.plcc-benefit-msg {
							font-size: $base-font - 1px;
						    line-height: 16px;
						}
					}
					.footer-container {
						.footer-email-signup {
							.email-signup {
								margin: 9px 0;
								
								.form-row {
									width: 60%;
									
								}
								.form-row-button {
									width: 40%;	
									
									button {
										float: left;
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
			
			.perks-offer {
			    padding-bottom: 25px;
		        margin-top: 0;
		        
				.per-off-content {
					padding: 4px 10px 10px;
					margin-top: 0;
					
					h1 {
						font-size: $base-font + 13px;
						letter-spacing: 6px;
					    margin: 0 0;
					    line-height: 30px;
					}
					span {
						font-size: $base-font;
					}
				}
			}
		}
		
		
		.plcc-bottom {
			padding: 0 0;
			
			.bottom-content {
		    	padding: 20px 0 0;
		    }
		    
			.bottom-right {
				margin: 50px 0 18px;
			}
			.plcc-text-content {
				font-size: $base-font - 3px;
    			line-height: 13px;
			}
			h1 {
				font-size: $base-font + 8px;
			}
		}
		.pre-approved-cta {
			
			.approved {
				font-size: $base-font;
			}
			.sign-in {
				font-size: $base-font;
				margin-top: 8px;
			}
			button {
		 		max-width: 62%;
			}
		}
	}
}


@media screen and (max-width: 480px) {
	.pt_specials {
		#main {
			padding: 0 10px; 
		}
	}
	.plcc-landing-content {
		.perks-email-wrapper {
			padding: 0 0;
			
			.perks-signup {
				.plccbenefits-n-email {
					width: 100%;
				}
			}
		}
	}
}	
.plcc-landing-content{
	.plcc-top-content {
		.ww.athenticated-cardholders, .ww.authenticated-prescreened-non-pre-approved {
			.text-content{
				top: 17%;
				right: 10%;
				@media screen and (max-width: 1024px) {
					top: 3%;
				}
				@media screen and (max-width: 767px) {
					top: 0;
					right: 0;
				}
				.msg{
					font-size: 21px;
				}
			}
		}
	}
}
