.js .simple-submit {
    display: none;
}

legend {
    background: $white-smoke;
    border-bottom: 1em solid $white;
    font-size: 1.1em;
    font-weight: 700;
    margin: 0;
    padding: 1.16em 2% .83em;
    position: relative;
    text-transform: uppercase;
    width: 96%;
    span {
        font-size: 12px;
        font-weight: 400;
        position: relative;
        text-transform: none;
        padding: 0 .5em;
        em {
            color: $night-rider;
        }
    }
}
.ie7 legend {
    width: 94%;
}

label {
    display: block;
    font-size: $base-font;
    font-weight: normal;
    line-height: normal;
    margin-bottom: 0;
}
.form-horizontal label {
    float: left;
    margin-top: .5rem;
    margin-bottom: 0;
    width: 100%;
    @media screen and (min-width: 768px) {
        text-align: right;
        width: 25%;
    }
}
.label-inline label {
    display: inline;
    float: none;
    font-size: 1em;
    font-weight: 400;
    margin-top: 0;
    text-align: left;
    width: auto;
}
.label-above label {
    margin-bottom: .3rem;
    width: auto;
}

.form-label-text {
    float: left;
    font-size: 1.1em;
    font-weight: 700;
    padding: 0.75em 0 0;
    text-align: right;
    width: 25%;
}

.field-wrapper {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin-bottom: .5rem;
    width: 100%;
}
.form-horizontal .field-wrapper {
    float: left;
    width: 76%;
    @media screen and (min-width: 768px) {
        padding-left: 2%;
        width: 50%;
    }
}
.label-inline .field-wrapper {
    float: left;
    padding-left: 0;
    width: auto;
}
.label-above .field-wrapper {
    float: none;
    padding-left: 0;
    width: auto;
}

select {
    background-color: $white; // set background color to make height work
    width: 100%;
    height: 2rem;
}

.form-caption {
    clear: left;
    width: auto;
}
.form-horizontal .form-caption {
    @media screen and (min-width: 768px) {
        margin-left: 25%;
        float: right;
    }
}
.label-above .form-caption {
    margin-left: 0;
}
.form-indent .form-caption {
    margin-left: 0;
}

.form-indent {
    @media screen and (min-width: 768px) {
        margin-left: 27%;
    }
}

.error-message {
    border: 2px solid $medium-carmine;
    background-color: $geraldine;
    padding: 0.7em 0.5em;
    text-indent: 0;
    width: 49.5966%;
}

.error {
    color: $medium-carmine;
}

span.error,
div.error {
    background: none;
}

.error-form {
    background-color: $stark-white;
    border: 1px solid $medium-carmine;
    border-radius: .5em;
    color: $medium-carmine;
    margin: 0.5em 0;
    padding: 0.8em;
}

.form-inline {
    form {
        padding: 0 0 2em;
        width: 98%;
    }
    label {
        text-transform: uppercase;
        width: auto;
    }
    input[type="text"],
    select {
        margin-left: 0;
        width: 95%;
    }
    button {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

.row-inline {
    display: inline;
    label {
        width: auto;
    }
}

// Required Indicator and Text
.dialog-required {
    display: inline;
    position: relative;
    padding: 0 .5em;
    em {
        color: $sangria;
        font-size: .65rem;
        font-style: normal;
        text-transform: uppercase;
    }
}

.required-indicator {
    color: $sangria;
    padding: 0;
}

.form-field-tooltip {
    float: left;
    margin-left: 3%;
    padding-top: .75em;
    width: 20%;
}

ul {
    li {
        list-style-type: none;
    }
}

// override TBR
.subscribe-action.clearboth .subscribe-emailid .field-wrapper input[type=email] {
    padding: 19px 0 18px 17px;
    border-radius: 0;
    @media screen and (max-width: 767px) {
        border-radius: 0;
    }
}

.subscribe-action.clearboth .subscribe-emailid .form-row.form-row-button button {
    border-radius: 0;
    background-color: $black;
    border-color: $black;
    @media screen and (max-width: 767px) {
        border-radius: 0;
    }
}
/// override TBR
  
// label animation on input focus change
.make-label-absolute {
    .form-row {
        label {
            span {
				@include WorkSansSemiBold($WorkSans);
				font-weight: normal;
                transition: all 0.3s ease;
                &.error {
                    color: $medium-carmine;
                }
            }
            &.input-focus {
                span {
                    font-size: $base-font - 1px;
                    transition: all 0.3s ease;
                }
            }
        }
    }
}

.field-wrapper .custom-select,
.pt_account .catalog-request .address-form-block .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_account .address-list .address-form-block .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_account .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_account .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_checkout .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_checkout .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_account .catalog-request .address-form-block .make-label-absolute .form-row .field-wrapper .custom-select,
.form-row .field-wrapper .custom-select,
.field-wrapper .custom-select {
    &.current_item {
        .selected-option,
        select {
			border-radius: 0;
			border-bottom: 1px solid;
    		border-color: $black; 
        }
    }
}

.payment-form .custom-select .selected-option[rel='Expiry Month'] {
    &:before {
        right: 2.5px;
        top: 40%;
    }
}

.custom-select .selection-list{
    border-color: #aaaaaa;
}

@mixin inputs_mixin {
    padding: 16px 0 15px 20px;
    border-color: $black;
}

@mixin label_mixin($translateX: 0, $translateY: 15px) {
    padding: 0 0 0 17px;
    letter-spacing: .5px;
    transform: translate($translateX, $translateY);
}

.field-wrapper,
.pt_account .catalog-request .address-form-block .make-label-absolute .form-row .field-wrapper,
.pt_account .address-list .address-form-block .make-label-absolute .form-row .field-wrapper,
.pt_account .make-label-absolute .form-row .field-wrapper {
    input[type=date] textarea,
    input[type=number],
    input[type=password],
    input[type=text],
    input[type=tel] {
        @include inputs_mixin();
    }
    
    button {
    	&.pwd-show {
    		letter-spacing: .5px;
    		@include WorkSansBold ($WorkSans);
    	}
    }
}

.pt_account .make-label-absolute label,
.pt_account .catalog-request .address-form-block .make-label-absolute .form-row label,
.pt_account .address-list .address-form-block .make-label-absolute .form-row label,
.pt_account .make-label-absolute .form-row label,
.pt_account .address-conatiner .make-label-absolute .form-row.error-handle.postal.required label,
.pt_checkout .make-label-absolute .form-row label,
.pt_account .make-label-absolute .form-row.error-handle label,
.pt_account .email-signup.make-label-absolute .form-row label,
.pt_article-page .make-label-absolute .form-row label {
    @include label_mixin();
    
    &.input-focus {
        letter-spacing: .4px;
    }
}