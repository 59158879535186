.pt_offerscoupons {
	.offers-below-content {
		width: 100%;
		display: block;
		max-width: 1260px;
		margin: 0 auto;
		
		.offers-recommendations {
			margin: 30px auto;
			text-align: center;
			.offers-recommendations-tiles{
				padding: 0 20px;
			}
			@media screen and (max-width: 1024px) {
				padding: 0 10px;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
			}
			
			@media screen and (max-width: 1023px) {
				padding: 0 20px;
			}
			
			@media screen and (max-width: 767px) {
				padding: 0 10px;
			}
		}
		
		.recommendation-heading {
			.trending {
			    font-size: $base-font + 12px;
			    text-transform: capitalize;
			    @include WorkSansMedium($WorkSans);
			    margin: 10px 0 40px 0;
			    border-bottom: 1px solid $alto;
			    padding: 0 0 15px 0;
			    text-align: left;
			}
		}
		
		.product-tile  {
			margin: 0 20px;
    		max-width: inherit;
    		width: 20%;
    		vertical-align: top;
    		@media screen and (max-width: 1023px) {
				margin: 0 10px;
			}
			
    		.p-image-rollover {
    			display: none;
    		}
		}
		
		ul {
			&.slick-dots {
				list-style-type: none;
				position: relative;
				margin: 0 auto;
				width: 100px;
				
				@media screen and (max-width: 1023px) {
					display: table;
					z-index: 3;
					padding: 10px 0 5px;
					
					li {
						display: table-cell;
						vertical-align: middle;
						margin: 0 auto;
						padding: 0;
					
						button ,button[type=button] {
							position: relative;
							border:none;
							border-radius:100%;
							width: 0;
							height: 0;
							padding: 2.5px;  
							background: $black;
							margin: 0 auto;
							color: transparent;
						}
					
						&.slick-active button{
							background: $light-gray;
							color: transparent;
	
						}
					}
				}
			}	
		}
		
		button {
			border: none;
			border-radius: 0;
			color: transparent;
			display: inline-block;
			padding: 0;
			margin: 0 auto;
			position: absolute;
			width: 23px;
			height: 23px;
			transform: translateY(-50%);
			top: 35%;
			
			&.slick-arrow {
				margin: 0 auto;
				padding: 14px;
				
				&.slick-next {
					left: 100%;
					background: url('../../../../images/carat-right_Black.svg') no-repeat center /contain;
					margin: 0 auto;
					margin-left: -33px;
					
					&.slick-disabled {
						background: url('../../../../images/carat-right_Grey.svg') no-repeat center/contain;
					}
				}
				
				&.slick-prev {
					right: 100%;
					background: url('../../../../images/carat-left_Black.svg') no-repeat center/contain;
					margin: 0 auto;
					margin-right: -33px;
					
					&.slick-disabled {
						background: url('../../../../images/carat-left_Grey.svg') no-repeat center/contain;
					}
				}
			}
		}
	}
}