$fatReg:3px;
$hoverColor: $black;

.category-refinement{
	#category-level-3 li.expandable {
		&.non-active {
			ul {
				display: none !important;
			}
		}
	}
}


#category-level-1 {
	li {
		.refinement-link {
			@include WorkSansMedium($WorkSans);
			font-size: $base-font + 2px;
			&.active {				
				color: $hoverColor;
				@include WorkSansBold($WorkSans);
			}
			&:hover {
				color: $hoverColor;
				@include WorkSansBold($WorkSans);
			}
		}
	}
	
	> li{
		> .refinement-link {
			font-size: $base-font + 2px;
		}
	}
}

#category-level-2 {
	
	> li{
		&.expandable {
			font-weight: bold;
			color: $hoverColor;
		}
	}

	.currCategory .cr-section a.active{
		color: $hoverColor !important;
		font-weight: 700 !important;
	}
}

.category-refinement {
	#category-level-1 {
		li {
			a {
				font-size: $base-font + 2px;
				text-transform: capitalize;
			}
			.cr-section {
				
				a {
					letter-spacing: 0;
					&.active {
						font-size: $base-font + 2px - $fatReg;
					}
				}
			}
		}

		#category-level-2 > li {
			&.expandable{
				.cr-section a{
						color:$black;
						@include WorkSansBold($WorkSans);
						font-size: $base-font + 2px;
					&.active{
						font-size: $base-font + 2px;
					}
					&:hover{
						color:$hoverColor;
					}
				}
				#category-level-3 li{
					a{
						&.active{
							font-size: $base-font + 2px;
						}
						&:hover{
							color: $hoverColor;	
						}
					}
					
				}
				&.not-bold {
					>.cr-section >a:hover{
						color:$hoverColor;
						font-weight: bold;
					}
				}
				&.bold-cat {
					padding: 15px 0 0;
					&:first-child {
						padding-top: 0; 
					}
					>.cr-section {
						padding-bottom: 5px;
						>a{
							font-size: 14px;
						}
					}
					#category-level-3 {
						li{
							padding: 15px 0;
							&.active {
								padding-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}

.category-refinement {
	#category-level-1 {
		#category-level-2 > li.expandable{
			#category-level-3 > li{
				&:first-child {
					padding:15px 0;
				} 
				padding:30px 0 0;
				a{
					&:hover{
						font-weight: bold;
						
					}
				}
				#category-level-4 {
					>li{
						&:first-child{
							padding-top: 30px;
						}
					}
					a.refinement-link{
						margin-left: 30px;

					}
				}
			}
		}
	}
}

.bold-cat #category-level-3 {
	padding: 15px 0 !important;
}

