.pt_account {
	.reset-message-notify {
	    @include WorkSansMedium($WorkSans);
	}
}

.email-trouble-message {
	 @include WorkSansMedium($WorkSans);
	font-size: $base-font + 1px;
	color: $white;
	background: $dim-gray;
}

.account-overview-heading {
	font-family: $HurmeBlack;
	letter-spacing: 3px;
	font-size: $base-font + 12px;
	text-transform: uppercase;
}

//My Account Overview
#secondary {	
	.secondary-navigation {
		max-width: 1024px;
		
	    .main {
	    	margin: 12px 0;
	    	font-size: $base-font + 14px;
	    	font-family: $HurmeBlack;
	    	letter-spacing: 3px;
	    }
	    
	    .navigation-links-row {
    		li {
				margin: 20px 39px 0;
				
				&.navigation-links-row-inner {
					li {
						margin: 30px 30px 0;
						word-spacing: 2px;
					}
				}
				
				a {
					font-size: $base-font + 2px;
					font-family: $HurmeSemiBold;
					font-weight: 600;
	    			letter-spacing: 2px;
					color: $FB-light-black;
					&:after {
						content: '';
						display: block;
						transform: scaleX(.1);
						height: 3px;
						background: transparent;
						transition: transform .3s;
						position: relative;
						top: 2px;
					}
					&:hover {
						&:after {
							transform: scaleX(1);
							background: black;
						}
					}
	    			
	    			@media screen and (min-width: 768px) and (max-width: 1023px) {
						letter-spacing: 1.8px;
					}
				}
    		}
    	}
	}
}
#wrapper {
	@each $pagename in $pagenames {
		&.#{nth($pagename, 1)} {
			#secondary {
				.secondary-navigation {
					.secondary-navigation-links {
						li {
							&.#{nth($pagename, 2)} {
								a {
									font-family: $HurmeBlack;
									font-weight: 900;
									
									@media screen and (min-width: 768px) and (max-width: 1023px) {
										letter-spacing: 1.6px;
									}
								}
							}
						}
					}
				}
			}	
		}	
	}
}

@media screen and (max-width: 767px) {
	#secondary {
		.secondary-navigation {
		    .secondary-navigation-links {
		    	padding: 0 20px 20px 20px;
 	 			background: $light-skygrey;
 	 			
 	 			&.active {
 	 				display: block;
 	 			}
		    }
		    
		    .heading {
			    background: $light-skygrey;
			    font-size: $base-font + 2px;
    			font-family: $HurmeBold;
				font-weight: 900;
    			letter-spacing: 2px;
    			color: $FB-light-black;
		    }
		    
		    .navigation-links-row {
	    		li {					
					&.navigation-links-row-inner {
						li {
							padding: 0;
							word-spacing: normal;
						}
					}
	    		}
	    	}
		}
	}
}