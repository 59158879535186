.catalog-thankyou-main {
	max-width: 960px;
	margin: 0 auto;
	
	.catalog-request-confirmation {
		 @include WorkSansMedium($WorkSans);
		font-size: $base-font;
		width: 100%;
		display: inline-block;
		margin: 0 0 25px 0;		
		box-sizing: border-box;
	}

	.catalog-thankyou {
		 @include WorkSansMedium($WorkSans);
		display: inline-block;
		width: 100%;
		margin: 30px 0 20px 2px;
	}
	
	.catalog-thankyou-heading {
		font-size: $base-font + 2px;
		 @include WorkSansMedium($WorkSans);
		margin: 0 0 5px 3px;
		display: inline-block;
		width: 100%;
	}
	
	.catalog-address {
		margin: 0 0 8px 3px;
		font-size: $base-font + 2px;
		 @include WorkSansMedium($WorkSans);
		display: inline-block;
		width: 100%;
	}
	
	.catalog-current {
		margin: 0 0 22px 4px;
		font-size: $base-font + 2px;
		 @include WorkSansMedium($WorkSans);
		display: inline-block;
		width: 100%;
		text-transform: capitalize;
	}
	
	.catalog-left {
		width: 15%;
		float: left;	
	}
	
	.catalog-right {
		float: left;
		width: 85%;
	}
	
	.current-brand-image {
		max-width: 117px;
		width: 100%;
	}
	
	.current-brand-name {
		.brand-logo {
			margin: 0 0 11px 0;
			width: 142px;
		   max-width: 100%;
		}
	}
	
	.current-brand-section {
		width: 100%;
	    display: inline-block;
	    padding: 0 0 22px 5px;
	    box-sizing: border-box;
	}
	
	.current-brand-benefits {
		font-size: $base-font + 1px;
		@include WorkSansMedium($WorkSans);
		width: 78%;
    	letter-spacing: 1px;
    	line-height: 19px;
	}
	
	.sister-brands {
		width: 100%;
		display: inline-block;
	}
	
	.sister-brand-message {
		font-size: $base-font + 12px;
		 @include WorkSansMedium($WorkSans);
	    padding: 15px 0 15px 13px;
	    width: 100%;
	    display: inline-block;
	    border-top: 1px solid $alto;
	    border-bottom: 1px solid $alto;
	   	margin: 0;
	    background-color: $light-skygrey;
	    box-sizing: border-box;
	}
	
	.sister-brand-rows{
		padding: 22px 26px 28px 15px;
		border-bottom: 1px solid $alto;
		width: 100%;
	    display: inline-block;
	    box-sizing: border-box;
	    
	    &.row-last {
	    	border-bottom: none;
	    	.brand-logo{
	    		margin-bottom: 14px;
	    	}
	    }
	    
	    .brand-logo{
	    	margin: 0 0 10px;
	    }
	}
	
	.sister-brand-section {
		.form-row-button {
			float: right;
			margin: 2% 0 4% 0;
		}	
	}
	
	.sister-brand-image {
		width: 15.4%;
		float: left;
	}
	
	.sister-image-size {
		max-width: 100px;
		width: 100%;
	}
	
	.sister-brand-right {
		width: 84%;
		float: left;
		
		.form-row {
			width: 17.6%;
		    float: right;
		    text-align: left;
		    font-size: $base-font + 1px;
		    font-family: $Helvetica;
		    margin: 3px 0 0 0;
		    line-height: 25px;
		}
		
		input[type="checkbox"] {
			border: none;
			background: url(../../../images/checkbox.svg) no-repeat center;
			width: 25px;
			height: 24px;
			outline: none;
			margin: 0px 4px 0 1px;
			&:checked {
				background: url(../../../images/checkmark.svg) no-repeat center;
				width: 25px;
				height: 24px;
			}
		}
		
		.brand-logo {
			width: 142px;
		}
	}
	
	.current-brand-description {
		width: 63.7%;
	    float: left;
	    margin: 0 18.5% 0 0;
	   	@include WorkSansMedium($WorkSans);
	   	line-height: 19px;
	   	
	   	h3 {
	   		margin-top: 0;
	   		line-height: 32px;
	   	}
	}
	
	.sister-request-submit {
		padding: 14px 68px;
		@include WorkSansSemiBold($WorkSans);
		letter-spacing: 1px;
		color: $white;
		background-color: $black;
		border-color: $black;
	}
}

//Catalog Preference confirmation
.catalog-confirmation {
    max-width: 1022px;
    margin: 0 auto;
    
    .catalog-request-header {
    	font-size: $base-font + 12px;
	    display: inline-block;
	    width: 100%;
	     @include WorkSansMedium($WorkSans);
	    padding: 0 0 60px 3.2%;
    	margin: 31px 0 14px 0;
	  	box-sizing: border-box;
    }
    
    .catalog-print-preference {
	    .catalog-request-confirmation {
	 		font-size: $base-font + 2px;  
	 		 @include WorkSansMedium($WorkSans); 	
	    }
	}
   
   .qa-section {
   		.qa-content {
   			padding: 22px 38px 20px 43px;
   			&.active {
   				padding: 22px 38px 34px 43px;
   			}
   			.question {
   				@include WorkSansBold($WorkSans);
				font-size: $base-font + 2px;
				letter-spacing: .5px;
   			}
   			
   			.answer {
   				@include WorkSansMedium($WorkSans);
   				&.active {
   					padding: 10px 0 0 0;
   				}
   			}
   		}
   		.question-title {
   			padding: 25px 25px 25px 38px;
   		}
   }
}

@media screen and (max-width: 1024px) {
	.catalog-confirmation {
		margin: 0 20px;
	}
}

@media screen and (max-width: 1023px) {
	.catalog-thankyou-main {
		margin: 0 20px;
	
		.current-brand-section {
			padding: 0 0 30px 0;
		}
		
		.catalog-left {
			width: 20%;
		}
		
		.catalog-right {
			width: 79%;
		}
		
		.current-brand-benefits {
			width: 71%;
			font-size: $base-font;
			 @include WorkSansMedium($WorkSans);
			letter-spacing: normal;
		}
		
		.sister-brand-rows {
			padding: 22px 18px 33px 0;
		}
		
		.sister-brand-image {
			width: 20%;
		}
		
		.sister-brand-right {
			width: 79%;
			.form-row {
				width: 17%;
				@include WorkSansMedium($WorkSans);
			}
		}
		
		.current-brand-description {
			width: 77%;
			margin: 0 5% 0 0;
			font-size: $base-font;
			 @include WorkSansMedium($WorkSans);
		}
		
		.sister-request-submit {
		 	padding: 14px 76px;
		}
		
		.catalog-current {
			@include WorkSansMedium($WorkSans);
		}
	}

	//Catalog Preference confirmation
	.catalog-confirmation {
		margin: 0 20px;
		
		.catalog-request-header {
			padding: 0 0 40px 0;
		}
		
		.catalog-request-confirmation {
			padding: 0;
		}
		
		.qa-section {
			.qa-content {
				.question {
					@include WorkSansBold($WorkSans);
					font-size: $base-font + 2px;
					letter-spacing: .5px;
				}
				
				.answer {
					font-size: $base-font + 2px;
					@include WorkSansMedium($WorkSans);
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.catalog-thankyou-main {
		margin: 0;
		
		.morecontent span {
		    display: none;
		}
		
		.morelink {
		    display: inline;
		    font-size: $base-font;
		    @include WorkSansSemiBold($WorkSans);
		    color: $black;
		}
		
		.catalog-thankyou {
			padding: 0 20px;
			box-sizing: border-box;
			margin: 30px 0 20px 0;
		}
		
		.catalog-request-confirmation{
			padding: 0 20px;
		}
		.catalog-address {
			padding: 0 20px;
			box-sizing: border-box;
			margin: 0 0 8px 0;
		}
		
		.catalog-current {
			padding: 0 20px;
			box-sizing: border-box;
			margin: 0 0 22px 0;
		}
		
		.current-brand-section {
			padding: 0 20px 30px 20px;
			box-sizing: border-box;
		}
		
		.catalog-left {
			width: 22%;
			float: right;
		}
		
		.catalog-right {
			width: 78%;
		}
			
		.current-brand-benefits {
		    width: 83%;
		    font-size: $base-font + 1px;
		}
		
		.current-brand-name {
			.brand-logo {
				margin: 0 0 5px 0;	
			}
		}
		
		.sister-brand-rows {
			padding: 22px 20px 33px 20px;
	    	box-sizing: border-box;
	    	.brand-logo {
	    		margin: 0 0 20px 0;
	    	}
		}
		
		.sister-brand-image {
			width: 22%;
			float: right;
		}
		
		.sister-brand-right {
			width: 78%;
			float: right;
		}
		
		.sister-brand-section {
			.form-row {
				margin: 20px 0 0 0;
	    		font-size: $base-font + 1px;
	    		width: 100%;
    			float: left;
    			
    			input[type="checkbox"] {
    				margin: 3px 0 0 0;
    			}
    			
    			label {
    				width: 70%;
				    float: right;
				    margin: 0;
				    font-size: $base-font + 1px;
    			}
			}
			
			.form-row-button {
				float: none;
			    padding: 0 20px;
			    width: 100%;
			    box-sizing: border-box;
			}
		}
		
		.sister-request-submit {
			width: 100%;	
		}
	}
	
	//Catalog Preference confirmation
	.catalog-confirmation {
		margin: 0 10px;
		.qa-section {
			.question-title {
				padding: 25px 0;
			}
			
			.qa-content {
				padding: 21px;
				&.active {
					padding: 21px;
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.catalog-thankyou-main {
		.sister-brand-image {
			width: 33%;
			float: right;
		}
		
		.sister-brand-right {
			width: 67%;
		}
		
		.catalog-left {
			float: right;
			width: 33%; 
			margin: 13px 0 0 0;  
		}
		
		.catalog-right {
			width: 67%;
		}
	}
}