footer {
	background-color:  $white;	
}
.footer-container {
    border-top: 1px solid $black;
    
    &.success-message {
    	.footer-email-signup-message {
    		display: none;
    	}
    	
    	.footer-email-signup {
    		width: auto;
    	}
    	
    	.email-signup {
    		&.make-label-absolute {
    			margin: 40px 0;
    		}
    	}
    	
        .email-signup-footer-success {
        	text-align: center;
        	
        	.email-signup-footer-success-heading {
        		font-size: $base-font + 12px;
        		 @include WorkSansMedium($WorkSans);
        		margin: 0 0 15px 0;
        		width: 100%;
        		float: left;
        	}
        	
        	.promo-code {
        		font-size: $base-font + 2px;
        		 @include WorkSansMedium($WorkSans);
        		margin: 0 0 13px 0;
        		width: 100%;
        		float: left;
        		color: $dim-gray;
        	}
        	
        	p {
        		font-size: $base-font + 2px;
        		 @include WorkSansMedium($WorkSans);
        		color: $dim-gray;
        		width: 100%;
        		float: left;
        	}
        }
    }
    
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: outside none none;
            margin: 0;
            padding: 0;
            line-height: normal;
            a {
                margin: 0;
                padding: 0;
            }
        }
    }
    p {
        margin: 0;
        padding: 0;
        line-height: normal;
    }
    .footer-email-signup-message {
        float: left;
        width: 100%;
        .footer-email-heading {
            text-align: center;
           	font-family: $HurmeSemiBold;
            font-size: $base-font + 8px;
            letter-spacing: 1px;
            margin: 33px 0 8px 0;
            text-transform: uppercase;
            
            @media screen and (max-width: 1023px) {
            	font-size: $base-font + 8px;
            }
            
            @media screen and (max-width: 767px) {
            	font-size: $base-font + 3px;
            }
        }
        p {
            text-align: center;
            @include WorkSansRegular($WorkSans);
            color: $black;
            font-size: $base-font + 2px;
            letter-spacing: 0.5px;
            
            @media screen and (max-width: 767px) {
            	font-size: $base-font - 1.5px;
            }
        }
    }
    .footer-email-signup {
        display: table;
        margin: auto;
        .email-signup {
            margin: 20px 0;
            &.make-label-absolute {
                .form-row {
                    input[id*="emailsignup_email"] {
                        padding: 15px 0 16px 15px;
                        font-size: $base-font + 1px;
                        line-height: $base-font + 2px;
                        height: 46px;
                        border-bottom: 0;
                    }
                }
            }
            .form-row {
                float: left;
                .field-wrapper {
                    height: 50px;
                    border-bottom: 4px solid $black;
                }
                input[type="text"] {
                    width: 310px;
                    height: 50px;
                    padding: 15px;
                    border: 1px solid $FB-lightest-gray;
                    border-right: none;
                    border-radius: 0;
                    font-family: $WorkSans;
                }
                .error {
                    clear: both;
                    float: left;
                    padding-top: 5px;
                }
            }
            .form-row-button {
                clear: none;
                button {
                    background-color: $black;
                    text-transform: uppercase;
                    font-family: $HurmeBlack;
                    letter-spacing: 4px;
                    
                    @media screen and (max-width: 1023px) {
                    	font-size: $base-font + 2px;
                    }
                }
            }
            
            &.make-label-absolute {
                margin-top: 34px auto 54px auto;
            	.form-row {
            		&.form-row-button {
            			button {
            				padding-top: 15px;
                            padding-bottom: 15px;
                            height: 50px;
                            font-size: $base-font + 2px;
    						
    						@media screen and (max-width: 767px) {
    							font-size: $base-font + 2px;
    						}
            			}
            		}
            	}
            }
        }
    }
    .footer-social-icon {
        position: relative;
        margin-bottom: 28px; 
        	.fo-border { 
        		border-bottom: 1px solid $black;
            }  
        .fo-social {
            ul {
                li {
                    &.instagram {
                        a {
                            background-position: -17px -16px;
                        }
                    }
                    &.pintrest {
                        a {
                            background-position: -53px -15px;
                        }
                    }
                    &.facebook {
                        a {
                            background-position: -83px -16px;
                        }
                    }
                    &.twitter {
                        a {
                            background-position: -114px -16px;
                        }
                    }
                    &.youtube {
                        a {
                            background-position: -157px -14px;
                        }
                    }
                    a {
                    	.icon {
                    		font-size: 30px;
                    		@media screen and (max-width: 767px) {
                    			font-size: 17px;
                    		}
                    	}
                    }
                }
            }
        }
    }
    .footer-links {
        float: left;
        width: 100%;
        border-bottom: 1px solid $black;
        .footer_list_wrapper {
            margin: auto;
            width: 100%;
            .footer_item {
                float: left;
                width: 25%;
                ul {
                    margin: 0 0 32px;
                    padding: 0;
                    li {
                        padding-top: 11px;
                        @media screen and (max-width: 1023px) {
                            padding-top: 15px;
                        }
                    }
                }
                .footer-heading {
                    text-transform: uppercase;
                    margin: 0;
                    font-family: $HurmeSemiBold;
                    font-weight: 600;
                    font-size: $base-font + 2px;
                    letter-spacing: 0.8px;
                    margin-bottom: 10px;
                    
                    a {
                        text-transform: uppercase;
                        font-family: $HurmeSemiBold;
                        font-weight: 600;
	                    font-size: $base-font + 2px;
	                    letter-spacing: 0.8px;
                        display: inline-block;
	                    
	                    @media screen and (max-width: 1023px) {
                            font-family: $HurmeBold;
                            font-size: $base-font;
                            letter-spacing: 1.5px;
	                    	text-transform: uppercase;
	                    	color: $black;
	                    }
                    }
                    
                    @media screen and (max-width: 1023px) {
                        margin-bottom: 0;
                    	font-size: $base-font;
                    	text-transform: uppercase;
                        color: $black;
                        font-family: $HurmeBold;
                    }
                }
                a {
                    text-transform: capitalize;
                    font-size: $base-font + 2px;
                    font-family: $WorkSans;
                    font-weight: normal;
                    letter-spacing: normal;
                    display: inline-block;
                    color: $black;

                    @media screen and (max-width: 1023px) {
                        color: $FB-dark-gray;
                        letter-spacing: 1px;
                    }
                    
                    &:hover {
                    	color: $black;
                    }
                }
            }
        }
    }
    .footer-item {
        box-sizing: border-box;
        display: block;
        padding: .5em 1.5em;
        width: 100%;
        @media screen and (min-width: 768px) {
            display: table-cell;
            padding: 1.5em;
            width: 25%;
        }
    }
    h3 {
        text-transform: uppercase;
    }
    .menu-footer {
        float: none;
        margin: .5em;
        padding: 0;
        @media screen and (min-width: 768px) {
            margin: 1em 0;
        }
        li {
            float: none;
            line-height: 2.5em;
            list-style: none !important;
        }
    }
    a {
        &:hover {
            color: $very-light-gray;
            text-decoration: none;
        }
    }
    .footer-brand-selector {
        float: left;
        width: 100%;
        .footer-brand-heading {
            font-family: $HurmeSemiBold;
            font-size: $base-font + 8px;
            letter-spacing: 3px;
            margin: 0;
            padding-bottom: 23px;
            padding-top: 27px;
            text-align: center;
            width: 100%;
            color: $black;
            text-transform: uppercase;
            
            @media screen and (max-width: 767px) {
                font-family: $HurmeBold;
                font-size: $base-font;
            }
        }
        .brands-list {
            ul {
                li {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .footer-legal-copyrights {
        float: left;
        width: 100%;
        .static-links {
            display: table;
            margin: auto auto 15px;
            text-align: center;
            ul {
                li {
                    display: inline-block;
                    margin-right: 10px;
                    a {
                        @include WorkSansRegular($WorkSans);
                        font-size: $base-font + 2px;
                        letter-spacing: normal;
                        text-decoration: none;
                        
                        @media screen and (max-width: 1023px) {
                        	font-size: $base-font;
                        	color: $FB-dark-gray;
                        }
                        
                        @media screen and (max-width: 767px) {
                        	font-size: $base-font + 2px;
                        	text-decoration: underline;
                        }
                    }
                    img {
                    	display: none;
                    }
                }
            }
        }
        .copyrights-wrapper {
            float: left;
            width: 100%;
            margin-bottom: 25px;
            p {
                float: left;
                text-align: center;
                width: 100%;
                color: $black;
                @include WorkSansRegular($WorkSans);
                font-size: $base-font + 2px;
                letter-spacing: 0;
                line-height: 20px;
                
                @media screen and (max-width: 1023px) {
                	font-size: $base-font;
                	color: $FB-dark-gray;
                }
                
                @media screen and (max-width: 767px) {
                	font-size: $base-font - 2px;
                }
            }
            .copy-arr {
                margin-bottom: 1px;
            }
        }
    }
}

.footer-container {
    &.footer-down {
        border-top: inherit;
        background-color: $FB-lightest-gray;
        float: left;
        width: 100%;
        margin: 40px auto auto;
        
        .footer-down-inner {
            width: 1260px;
            margin: 40px auto 0;
        }
    }
}

@media screen and (max-width: 767px) {
    footer {
        .footer-container {
            .footer-email-signup {
                width: 96%;
            }
            .footer-links {
                .footer_list_wrapper {
                    .footer_item {
                        .footer-heading {
                            font-size: $base-font;
                        }
                        a {
                        	font-size: $base-font;
                        	text-transform: capitalize;	
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    footer {
        .footer-container {
            .footer-email-signup {
                width: 52%;
                .email-signup {
                    margin: 14px 0;
                }
            }
            .footer-links {
                .footer_list_wrapper {
                    .footer_item {
                        ul {
                            margin: 0 0 22px;
                            li {
                                padding-top: 11px;
                            }
                        }
                        a {
                            font-size: $base-font;
                            text-transform: capitalize;
                            color: $FB-dark-gray;
                        }
                    }
                }
            }
            .footer-brand-selector {
                margin-bottom: 18px;
                .footer-brand-heading {
                    padding-bottom: 37px;
                    padding-top: 34px;
                    font-size: $base-font + 2px;
                }
            }
        }
    }
}