.pt_specials {
	
	#navigation {
		.menu-category {
			li {
				&.selected {
					a {
						color: $black;
						border-bottom: 4px solid $black;
					}
				} 
			}
		}
	}


	
	h1 {
		&.mm-heading {
		    word-spacing: 5px;
	    	letter-spacing: 9px;
	    	@include WorkSansBold($WorkSans);
		} 
	}
	
	.mixmatch-slide {
    	
		.special-result-content {
	    	
	    	&:first-child {
	    		.mm-prod-details {
    			     margin-top: 10.3%;
	    		}
	    	}
	    	
	    	.mm-prod-details {
			    width: 20%;
		        
		        .product-variations {
    				.attribute {
	    				
    					.custom-select{ 
    						.variation-select{
    							@include WorkSansBold ($WorkSans);
							    font-size: $base-font - 1px;
    						}
    						
    						&.error {
    							.selected-option,.variation-select {
    								color: $medium-carmine;
    							}
    						}	
    					}
    				}
    				select{
    					font-family: $WorkSans;
    					font-weight: 700;
    				}
    				
			    }
		        
			    .product-name {
			    	 @include WorkSansMedium($WorkSans); 
			    	font-size: $base-font + 2px;
			    }
			    
			    .disablemessage {
			    	color: $RedRibbon;
			    	@include WorkSansMedium ($WorkSans);
			    }
			    
			    .product-price {
			    	@include WorkSansMedium($WorkSans)
		    	    font-size: $base-font;
		    	    
		    	    .price-sales {
		    	    	color: $RedRibbon;
		    	    }
			    }
			    
			    
			    .size-chart-link {
			    	@include WorkSansBold	($WorkSans);
			    	font-size: $base-font - 1px;
			    	background: url("../../../images/size-chart.png") left center no-repeat;
			    }
			    
			    button {
			    	font-size: $base-font + 2px;
				    padding: 17px 0;
				    border: 0;
				    @include WorkSansSemiBold($WorkSans);
				    
				    &.add-to-cart{
			    	    background-color: $black;
					    
				    }
			    }
			    
			    .special-product-view {
			    	@include WorkSansBold($WorkSans);
			    	font-size: $base-font;
			    }
	    	}
			.slick-slider {
	    	    margin: 70px 0 50px;
		        
		    	.slick-slide {
		    		
		    		
	    		    
    		    	.product-variations{
    		    		ul {
    		    			text-align: center;
    		    		}
    		    	}
    		    	
	    		    &.slick-center {
			    	    .product-variations{
			    	    	width: 325px;
		    		    	@media screen and (min-width:1025px) {
	    						margin-left: -11%;
	    					}
	    		    		 .attribute{
	    		    		    border: 0;
		    		    	}
		    		    	
	    		    	}
	    		    }
		    	}
		    	    	
		    	.product-variations {
		    		.attribute {
		    			.color {
		    				li {
						      	margin: 3px;
						        
							    &.selected {
							    	a {
							    		&.swatchanchor {
						    				border: 1px solid $black;
    										border-radius: 50%;
							    		}
							    	}
							    }
					    		a {
					    			&.swatchanchor {
									    min-width: 39px;
									    min-height: 39px;
	    								border: 1px solid transparent;
					    			}	
					    			
					    			img {
									    box-sizing: border-box;
									    border-radius: 50%;
			    					}
					    		}
					    	}
		    			}
		    			@media screen and (max-width: 767px) {
			    			.color{
						        
			    				li{
								    &.more-less-colors {
								    	a {
											font-family: $HelveticaMedium;
											font-size: $base-font - 1px;
								    	}
								    }
								}
			    			}
				    	}
		    		} 
		    	}
				.slick-arrow {
				    top: 165px;
					&.slick-next {
				        background: url("../../../images/slick-next.png") right no-repeat;
					}
					&.slick-prev {
				        background: url("../../../images/slick-prev.png") left no-repeat;
					}
				}
			}
		}
	}
}

// design only for 1024px layout
@media only screen and (width:1024px){
	.pt_specials {
		ul {
			&.custom_paging {
				    
				li {
					@include WorkSansMedium($WorkSans);
					
				}
			}
		}
		
		.mixmatch-slide {
			.special-result-content {
				
				.mm-prod-details {
				}
				.slick-slider {
					.slick-slide {
						.product-variations {
							width: 325px;
							.attribute {
								border: 0;
							}
						}
					}
				}
			}
		} 
	}
}
@media screen and (max-width:1023px) {
	.pt_specials {
		.mixmatch-slide {		    
		    .special-result-content {
		    	clear: both;
		    	
		    	&:first-child {
		    		border-bottom: 0;
		    	}
			} 
	    }
	    
	    ul {
			&.custom_paging {
			    @include WorkSansMedium($WorkSans);
			    font-size: 11px;				    
			}
		}
    }    
}

@media screen and (min-width:768px) and (max-width:1023px){
	.pt_specials {
		.mixmatch-slide {
			.special-result-content {
		    	
				.slick-slider{
				    
				    
				    .special-product-swatches {
						width: 325px;
					    margin-left: -11%;
					    
					}
				}
			} 
		}
		
	}
}

@media screen and (max-width:767px){
	.pt_specials {
		.mixmatch-slide {
			h2 {
				&.togglemob {
					background-image: url("../../../images/arrow-closed.png");
				    font-family: $WorkSans;
				    font-weight: 700;
				    font-size: $base-font;
			    }			    
			    &.expanded{
			    	background-image: url("../../../images/arrow-open.png");
		    	    border-bottom: 1px solid $very-light-gray;
			    }
			}
			.special-result-content {
				.slick-slider {
				    
				    .product-variations {
						.attribute {
							.color {
								li {
									a{
										img{
											padding: 2px;
										}
									}
								}
							}
						}
					}
				}
				.mm-prod-details {
					.custom-select{ 
						.selected-option{
							font-size: $base-font - 1px;	
						}
					}
				}
			} 
		}
	}
}
@media screen and (max-width:480px) { 
	.pt_specials {
		
		
		.mixmatch-slide {
			
		}
	}
}

