//Catagory Banner
.content-banner-breadcrumb {
    
    &.absolute {
    	.category-banner-text {
    		position: absolute;
    		top: 42%;
    		left: 20%;
    		width: auto;
    	}
    }
}

.slot-grid-header {
	position: relative;
	display: table;
	
	&.no-category-banner {
		height: 140px;
		background-color: $FB-lightest-gray;
	    display: table;
	    width: 100%;
	    
	   @media screen and (max-width: 1023px) {
	   		height: 120px;
	   }

	   @media screen and (max-width: 767px) {
	   		height: auto;
	   }

	    .category-banner-text {	    	
	    	text-align: center;
    		display: table-cell;
    		position: static;
    		margin: 0;
    		top: 0;
			left: 0;
			vertical-align: middle;
			@media screen and (max-width: 767px) {
				display: block;
				text-align: left;
			}
			.category {
				font-family: $HurmeBold;
				font-size: $base-font + 28px;
				letter-spacing: 1px;
				margin: 0;
				
				@media screen and (max-width: 1023px) {
					   font-size: $base-font + 20px;
				   }
		
				   @media screen and (max-width: 767px) {
					   font-size: $base-font + 6px;
					   margin: 10px 0 10px 14px;
					   letter-spacing: 3px;
				   }
			}
    		p {
    			color: $black;
    			@include WorkSansRegular($WorkSans);
    			font-size: $base-font + 10px;
    			margin: {
    				top: 15px;
    				bottom: 15px;
    			}
    			
    			@media screen and (max-width: 1023px) {
			   		font-size: $base-font + 8px;
			   		margin-bottom: 0;
			   	}
		
			   	@media screen and (max-width: 767px) {
			   		font-size: $base-font + 4px;
			   	}
    		}
	    }
   	}    
	.standard-contentslot {
		position: relative;
		z-index: 1;
		height: 25px;
		
		@media screen and (max-width: 1023px) {
			height: auto;
		}
	}

	img {
		height: auto;
	    max-width: 100%;
	    width: 100%;
	}
	
	.category-banner-text {
		position: relative;
	    box-sizing: border-box;
	    width: 100%;
    	
	    .category {
	    	font-size: $base-font + 16px;
		    letter-spacing: 1px;
		    @include WorkSansBold ($WorkSans);
		    text-transform: uppercase;
		    margin: 0;
	    }
	    
	    p {
	    	font-size: $base-font + 4px;
	    	@include WorkSansRegular($WorkSans);
	    }
	}
}

@media screen and (max-width: 1023px) {
	.slot-grid-header {
		.category-banner-text {
			.category {
				font-size: $base-font + 16px;
				color: $black;
			}
			
			p {
				font-size: $base-font + 4px;
				padding: 0;
				color: $FB-dark-gray;
			}
		}
	}
}

@media screen and (max-width: 767px) {	 
	.slot-grid-header {
		.category-banner-text {
			position: relative;
			left: 0;
			text-align: center;
			top: 0;
			transform: inherit;
	        -ms-transform: inherit;
    	    -webkit-transform: inherit;
    	    margin: 0 0 10px 0;
    	    width: 100%;
    	    display: none;
    	    
			.category {
				font-size: $base-font + 13px;
			    letter-spacing: 6px;
			}
			
			p {
			    font-size: $base-font + 1px;
			}
		}
	}
}		