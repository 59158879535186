.pt_account {	
	.inner-block {
		&.check-order {
			h2 {
				font-size: $base-font + 12px;
				@include WorkSansMedium($WorkSans);
				letter-spacing: .5px;
				padding: 0 0 15px 0;
				border: {
					width: 2px;
		    		color: $black;
				}
			}
		}
	}
	
	.col-2 {
		h2 {
			font-size: $base-font + 1px;
			font-family: $WorkSans;
		    letter-spacing: 0;	
		}
	}
	
	.create-login {
		.inner-block {
			.login-box {
				background-color: $FB-lighter-gray;
				.error-form {
					background-color: transparent;
					border: none;
				}
			}
		}
	}
	
	.check-order {
		p {
			font-size: $base-font + 1px;
			font-family: $WorkSans;
		    letter-spacing: 0;
		    font-weight: normal;
		    line-height: 21px;
		}
		
		.ordererror {
			border: none;
		    background-color: $wild-sand;
		    padding: 0 0 10px 0;
		    color: $RedRibbon;
		}
	}
	
	.registration-reward {
		margin-top: 40px;
		
		h2 {
			font-size: $base-font + 6px;
    		@include WorkSansBold ($WorkSans);
    		letter-spacing: .5px;
    		text-transform: capitalize;
		}
		
		h3 {
			@include WorkSansBold ($WorkSans);
			font-size: $base-font + 2px;
			letter-spacing: .5px;
    		text-transform: capitalize;
		}
		
		p {
			font-size: $base-font + 2px;
			@include WorkSansRegular ($WorkSans);
			margin: 15px 0 20px 0;
			letter-spacing: .5px;
			line-height: 21px;
		}
	}
	
	.create-login {
		.login-create-account {
			min-height: 1006px;
			@media screen and (max-width: 767px) {
				min-height:auto;
			}
			.legal {
				@include WorkSansMedium($WorkSans);
				font-size: $base-font;	
			}
			@media screen and (min-width: 768px) {
				.form-row {
					&.addtoemaillist {
						label {
							padding-left: 10px;
						}
					}
				}
			}
		}
		
		.login-create {
			.registration-reward {
				p {
					@include WorkSansRegular ($WorkSans);
				}
			}
		}
	}
}

.create-login {
	.form-caption {
		@include WorkSansMedium($WorkSans);
		margin-left: 0;
	}
}

@media screen and (max-width: 767px) {
	.pt_account {
		.create-login {
			.login-create, .login-create-account {
				height: auto;
			}
			button {
				padding: 14px 25px;
			}
			h1 {
				.dialog-required {
					display: none;
				}
			}
		}
		.col-2 {
			.label-inline {
				.field-wrapper {
					margin: 0 10px 0 0;	
				}
			}
		}
		.benfits-content {
			.registration-reward {
				h2 {
					font-size: $base-font + 10px;
				}
			}
		}
		.registration-reward {
			p {
				margin: 0 0 30px 0;	
			}
			h3 {
				margin: 0;	
			}
		}
	}
	.create-login {
		.inner-block {
			.login-box{
				&.login-create-account {
					.create-account {
						padding: 14px 48px;
					}
				}
			}
		}
	}
}


