.related-slider-cont {
    .slick-track {
        margin-left: 0;
        display: flex;
    }
    .slick-initialized .slick-slide {
        height: inherit;
        & > div {
            height: 100%;
        }
    }
    .btn-sec .slider-btn {
        &.slick-hidden {
            display: none;
        }
    }
}


@include media-breakpoint-down(md) {
    .related-product-tile {
        max-width: 8.875rem;
        width: 8.875rem;
        min-width: 8.875rem;
    }
    .related-slider-cont .slick-list {
        margin-right: -0.75rem;
    }
}

.pill-list-container {
    white-space: nowrap;
    overflow-x: auto;
    @include media-breakpoint-up(lg) {
        white-space: normal;
        overflow-x: visible;
    }
}

.slider-btn {
    vertical-align: top;
    svg {
        width: 0.701rem;
        vertical-align: top;
    }
    &.related-next-btn {
        transform: rotate(180deg);
        margin-top: -0.563rem;
    }
    &.slick-disabled {
        opacity: .5;
    }
}

.related-product-slider .related-product-tile {
    .related-product-details {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 4.75rem;
    }

    &:hover {
        text-decoration: none;
        .related-product-title,
        .related-product-cta {
            text-decoration: underline;
        }
    }
}
