.product-monogramming, .product-hemmable {
	width: 100%;
    float: none;
    padding: 20px 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
 	-moz-box-sizing: border-box;
    border-top: 1px solid $light-gray;
    /*border-bottom: 1px solid $very-light-gray;*/
    
    &.error-msg {
    	.title {
    		color: $medium-carmine;
    	}
    }
    
    .heading {
    	width: 100%;
	    display: inline-block;
	    padding: 0 0 10px 0;
	    font-size: $base-font;
	    text-align: left;
	    
	    .title {
	    	width: auto;
    		float: left;
	    }
	    
	    .tips {
	    	width: auto;
    		float: right;
    		position: relative;
    		
    		.tips-link, .hemming-tips-link {
    			font-size: $base-font;
    			
    			&:before {
    				background: url('../../../images/tips.svg') no-repeat;
					width:16px;
					height:16px;
					background-size:cover;
				    padding: 0;
				    margin: 0;
				    content: '';
				    position: absolute;
				    left: -25px;
    			}
    		}
	    }
    }
    
    .form, .checkbox-content {
    	width: 100%;
    	float: none;
    	
    	label {
    		span {
    			text-transform: none;
    			font-size: $base-font;
			    @include WorkSansMedium ($WorkSans);
			    color: $black;
			    text-align: left;
			    line-height: 18px;
			    padding-left: 10px;
			    word-spacing: 3px;
			    font-weight: normal;
    		}
    	}
    	
    	input[type="checkbox"] {
    		margin: 0 0 0 2px;
    	}
    }
    
    .input-fields-set {
    	.character-left {
    		position: relative;
    		width: 100%;
    		clear: both;
    		.monogramming-charactercount {
    			position: absolute;
			    width: auto;
			    right: 15px;
			    top: 50%;
			    @include WorkSansMedium ($WorkSans);
    		}
    		
    		input[data-type="NAME"] {    			
    			&::-webkit-input-placeholder { 
					text-transform: capitalize;
				}
				&::-moz-placeholder { 
				  	text-transform: capitalize;
				}
				&:-ms-input-placeholder {
				  	text-transform: capitalize;
				}
				&:-moz-placeholder {
				  	text-transform: capitalize;
				}
    		}
    	}
    }
    
    .personalized {
    	width: 48%;
    	float: left;
    	
    	@media screen and (max-width: 767px) {
    		width: 100%;
    	}
    	
    	&.monogramming-color {
    		float: right;
    	}
    	
    	&.monogramming-position {
    		width: 68%;
    		
    		@media screen and (max-width: 767px) {
	    		width: 100%;
	    	}
    	}
    	
    	.custom-select {
    		margin: 20px 0 0 0;
    		
    		&.current_item {
    			.selected-option {
	    			&.selected {
					    border-bottom: 0;
						border: 2px solid $black;
		    		}
	    		}
    		}
    		
    		&.mono-error {
    			.selected-option, select {
    				color: $light-red;
    			}
    		}
    		
    		select {
    			@media screen and (min-width: 768px) and (max-width: 1024px) {
    				padding: 0 38px 0 12px;
    			}
    		}
    	}
    	
    	.selected-option {
    		height: 48px;
    		@include WorkSansMedium ($WorkSans);
    		font-size: $base-font;
    		color: $dusty-gray;
    		border: 1px solid $dusty-gray;
    		text-transform: capitalize;
    		
    		.selection-list {
    			li {
    				font-size: $base-font;
    				text-transform: capitalize;
    			}
    		}
    	}
    }	
    
    .input-fields {
    	height: 48px;
		margin: 20px 0 0 0;
    	
    	&.mono-error {
    		&::-webkit-input-placeholder { 
			  color: $light-red;
			}
			&::-moz-placeholder { 
			  color: $light-red;
			}
			&:-ms-input-placeholder {
			  color: $light-red;
			}
			&:-moz-placeholder {
			  color: $light-red;
			}
		}
    }
    
    .monogramming-preview {
    	position: relative;
    	
		textarea {
    		height: 100px;
    		margin: 20px 0 0 0;
    		width: 100%;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
 			-moz-box-sizing: border-box;
			border-radius: 0;
			padding: 20px 15% 0 15px;
			position: relative;
		}
		
		.refresh-link {
			background: url('../../../images/refresh.svg') no-repeat;
		    width: 26px;
		    height: 26px;
		    padding: 0;
		    margin: 0;
		    position: absolute;
		    top: 45%;
		    right: 0;
		    margin-right: 10px;
		    transform: translate(-50%);
		    -webkit-transform: translate(-50%);
		    -moz-transform: translate(-50%);
			-o-transform: translate(-50%);
			-ms-transform: translate(-50%);
		}
	}
}

.presonalised-msg-norefund {
	padding: 10px 0 0 0;
	font-size: $base-font;
	@include WorkSansMedium ($WorkSans);
	width: 100%;
    float: left;
    color: $mangotango;
}

.personalised-msg-valid-characters {
	font-size: $base-font;
	@include WorkSansMedium ($WorkSans);
	padding-top: 10px;
}

.hemming-drawer {
	width: 100%;
	float: left;
	margin: 20px 0 0 0;
	
	ul {
		li {
		    width: 40px;
		    border: 2px solid $black;
		    text-align: center;
		    vertical-align: middle;
		    cursor: pointer;
		    float: left;
		    margin-right: 10px;	
		    font-size: $base-font;
			@include WorkSansMedium ($WorkSans);
			margin-bottom: 10px;
			border-radius: 0;
			box-sizing: border-box;
			
			&.selected {
				background: $black;
				color: $white;
			}
		}
	}
}

.hemming-error-msg {
	font-size: $base-font;
	@include WorkSansMedium ($WorkSans);
	margin-top: 10px;
	color: $dark-red;
	width: 100%;
	float: left;
}

.ui-dialog {
	&.tips, &.product-hemming-tips {
		padding-top: 0;
		
    	.ui-dialog-title {
    		display: block;
    		font-size: $base-font - 1px;
			@include WorkSansBold ($WorkSans);
			padding-left: 25px;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
 			-moz-box-sizing: border-box;
    		margin-top: 20px;
    	}
    	
    	.ui-dialog-titlebar {
	    	position: relative;
		    display: block;
		    margin: 0 auto 10px;
		    width: 100%;
		    height: auto;
		    top: auto;
		    .ui-dialog-titlebar-close {
		        position: relative;
			    width: 25px;
			}
    	}
    	
    	
    	.ui-dialog-content {
    		padding: 0 14px 0 25px;
    		box-sizing: border-box;
    		-webkit-box-sizing: border-box;
 			-moz-box-sizing: border-box;
    		
    		p {
    			margin: 0 0 20px 0;
    			font-size: $base-font - 1px;
    			@include WorkSansMedium ($WorkSans);
    			
    			&:nth-child(3n) {
    				margin: 0 0 30px 0;
    			}
    			
    			span {
    				@include WorkSansBold ($WorkSans);
    				text-transform: uppercase;
    			}
    		}
    		
    		.tips-contents {
    			padding: 0 14px 0 0px;
    		}
    	}
	}
} 

.single-selection {  
	border-radius: 0;	  
    span {
    	font-size: $base-font;
    }
}